import { useEffect } from 'react'

import { RELAYER_BACKEND_BASE_URL } from 'config/constants/endpoints'
import axios from 'axios'
import useRefresh from 'hooks/useRefresh'
import * as Sentry from '@sentry/react'
import { TransactionStatus } from 'config/enums/transactionStatus'
import { useShouldFetchTransaction } from 'state/swap/selectors'
import { useSwapActionHandlers } from 'state/swap/hooks/index'
import { UPDATE_ACTIONS } from '../types'
import { useUpdateTransaction } from 'views/Swap/Default/hooks/postTransactions'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import { Field } from 'state/types'

// Handler to call in our views
export function useSwapTransactionsHandler() {
  const shouldFetchTransaction = useShouldFetchTransaction()
  const { getConnectedWalletAddress } = useWalletActionHandlers()
  const fromAddress = getConnectedWalletAddress(Field.INPUT)
  const toAddress = getConnectedWalletAddress(Field.OUTPUT)
  const { onUpdateShouldFetchTransaction, onUpdateSwapTransaction } = useSwapActionHandlers()
  const { updateTransactionStatusByIds } = useUpdateTransaction()
  // const { publicKey } = useWallet()
  // const { account } = useActiveWeb3React()
  const { tenSecondRefresh } = useRefresh()
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const { data: response } = await axios.post(
          `${RELAYER_BACKEND_BASE_URL}/transaction`,
          {
            evmAddress: fromAddress ? fromAddress : undefined,
            solAddress: toAddress ? toAddress : undefined,
            // tronAddress:
            //   window.tronWeb && window.tronWeb.defaultAddress && window.tronWeb.defaultAddress.base58
            //     ? window.tronWeb.defaultAddress.base58
            //     : undefined,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
          },
        )
        const transactions = response.data
        const pendingTransactions = transactions.filter(
          (txDetail) =>
            txDetail.isWithRelayer &&
            txDetail.status !== TransactionStatus.DONE &&
            txDetail.status !== TransactionStatus.FAILED,
        )
        if (shouldFetchTransaction === UPDATE_ACTIONS.FIRST_TIME) {
          const initiatedTransaction = transactions.filter(
            (txDetail) => txDetail.status === TransactionStatus.INITIATED,
          )
          const ids = initiatedTransaction.map((a) => a._id)
          await updateTransactionStatusByIds(ids)
          onUpdateShouldFetchTransaction(UPDATE_ACTIONS.SHOULD_UPDATE)
        } else if (shouldFetchTransaction === UPDATE_ACTIONS.SHOULD_UPDATE) {
          if (pendingTransactions.length === 0) {
            onUpdateShouldFetchTransaction(UPDATE_ACTIONS.SHOULD_NOT_UPDATE)
          }
        }
        onUpdateSwapTransaction(transactions)
      } catch (error) {
        Sentry.captureException(error)
      }
    } // end of fetch Transactions functions

    ;(fromAddress || toAddress) &&
      (shouldFetchTransaction === UPDATE_ACTIONS.FIRST_TIME ||
        shouldFetchTransaction === UPDATE_ACTIONS.SHOULD_UPDATE) &&
      fetchTransactions()
  }, [fromAddress, toAddress, tenSecondRefresh, shouldFetchTransaction])
} // end of use Swap Action Handler
