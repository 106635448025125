import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ACTION_STEP_STATE, IActionStepState } from 'state/swap/types'
import { NETWORKS_INFO, SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { getTransactionConfirmations, waitForSolanaTxConfirmation } from 'utils/callHelper'
import { updateCurrentStepPayload } from 'state/swap/actions'
import {
  getEmitterAddressEth,
  getEmitterAddressSolana,
  parseSequenceFromLogEth,
  parseSequenceFromLogSolana,
  uint8ArrayToHex,
} from '@certusone/wormhole-sdk'
import { getWormholeBridgeAddress, getWormholeTokenBridgeAddress } from 'utils/addressHelpers'
import { getSignedVAAWithRetry } from 'utils/getSignedVAAWithRetry'
import { useConnection } from '@solana/wallet-adapter-react'

// useWormholeBridge is to get tx confirmation
export const useWormholeBridge = () => {
  const dispatch = useAppDispatch()
  const { account, library } = useActiveWeb3React()
  const { connection: solanaConnectionObj } = useConnection()

  const handleBridgeActions = useCallback(
    async ({ chainId, receipt, lockedAmount, txid }): Promise<IActionStepState> => {
      try {
        if (!chainId || !txid || !lockedAmount) {
          throw new Error('Invalid Parameters for bridge.')
        }
        const totalConfirmations = NETWORKS_INFO[chainId as SupportedChainId].blockConfirmations
        let vaa = ''
        if (SupportedEVMChainId(chainId)) {
          // here means its EVM

          await getTransactionConfirmations(library, txid, totalConfirmations, (confirmations: number) => {
            const payload = {
              totalConfirmations,
              currentConfirmations: confirmations,
            }
            dispatch(updateCurrentStepPayload({ payload }))
          })

          const sequence = parseSequenceFromLogEth(receipt, getWormholeBridgeAddress(chainId))

          const emitterAddress = getEmitterAddressEth(getWormholeTokenBridgeAddress(chainId))

          const { vaaBytes } = await getSignedVAAWithRetry(
            NETWORKS_INFO[chainId as SupportedChainId].wormholeChainId,
            emitterAddress,
            sequence.toString(),
          )
          vaa = uint8ArrayToHex(vaaBytes)
        } else if (chainId === SupportedChainId.SOLANA) {
          // handle solana

          const SOL_TOKEN_BRIDGE_ADDRESS = getWormholeTokenBridgeAddress(chainId)
          const info = await waitForSolanaTxConfirmation(
            txid,
            'confirmed',
            solanaConnectionObj,
            totalConfirmations,
            (confirmations: number) => {
              const payload = {
                totalConfirmations,
                currentConfirmations: confirmations,
              }
              dispatch(updateCurrentStepPayload({ payload }))
            },
          )

          const sequence = parseSequenceFromLogSolana(info)
          const emitterAddress = await getEmitterAddressSolana(SOL_TOKEN_BRIDGE_ADDRESS)

          const wormHoleChainId = NETWORKS_INFO[chainId].wormholeChainId
          const { vaaBytes } = await getSignedVAAWithRetry(wormHoleChainId, emitterAddress, sequence, 20)
          vaa = uint8ArrayToHex(vaaBytes)
        } else {
          throw new Error('Invalid Supported chain id')
        }
        return {
          status: ACTION_STEP_STATE.OK,
          message: '',
          payload: {
            vaa,
            lockedAmount,
          },
        }
      } catch (error) {
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [dispatch, library, account],
  )

  return { onWormholeBridgeActions: handleBridgeActions }
}
