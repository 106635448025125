import { Box, Flex } from 'components/Box'
import useTheme from 'hooks/useTheme'
import { useChainInfo } from 'state/stableSwap/selectors'
import { Field } from 'state/types'
import { Modal } from 'widgets/Modal'
import { ListItem } from './../../Default/components/TokensListModal/styles'
import { FixedSizeList as List } from 'react-window'
import React, { useEffect, useState } from 'react'
import { Text } from 'components/Text'
import { ShimmerCard } from 'components/Svg'
import { useTranslation } from 'contexts/Localization'
import { useCurrencies } from 'state/stableSwap/selectors'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'
import useFetchCurrencyBalance from 'hooks/useFetchCurrencyBalance'
import { Currency, CurrencyAmount } from 'config/entities'
import Image from 'components/Image'
import { TokenInfoWithChainDetails } from 'config/allbridge/tokens-info'

interface ITokenListProps {
  handleDismiss: () => void
  field: Field
}

const TokensList: React.FC<ITokenListProps> = (props: ITokenListProps) => {
  const { handleDismiss, field } = props
  const chainID = useChainInfo(field)
  const { theme } = useTheme()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const tokenList = useCurrencies(field)
  const { onSelectCurrency } = useStableSwapActionHandlers()
  const { fetchCurrenciesBalances } = useFetchCurrencyBalance()
  const [currenciesWithBalance, setCurrenciesWithBalance] = useState<Currency[]>(() => {
    if (!tokenList) return []
    const _tokenList = tokenList.map((item) => {
      return {
        chainId: chainID,
        address: item.tokenAddress,
        decimals: item.decimals,
        symbol: item.symbol,
        name: item.name,
        balance: new CurrencyAmount(0),
        logoURI: '',
      }
    })
    return _tokenList
  })

  const handleSelectCurrency = (token: TokenInfoWithChainDetails) => {
    onSelectCurrency(token, field)
    handleDismiss()
  }

  useEffect(() => {
    const fetchBalance = async () => {
      setLoading(true)
      const tokensWithBalance = await fetchCurrenciesBalances(chainID, currenciesWithBalance)
      setCurrenciesWithBalance(tokensWithBalance)
      setLoading(false)
    }

    fetchBalance()
  }, [chainID, tokenList])

  const Row = ({ index, style }) => (
    <div style={style}>
      <ListItem
        key={index}
        onClick={() => {
          handleSelectCurrency(tokenList.find((item) => item.symbol === currenciesWithBalance[index].symbol))
        }}
      >
        <Image
          src={`./images/tokens/${currenciesWithBalance[index]?.symbol}.svg`}
          classes="rounded-circle symbol-icon"
        />
        <Box ml="10px">
          <Text className="token-symbol">{currenciesWithBalance[index].symbol}</Text>
          <Text className="wallet-name">{currenciesWithBalance[index].name}</Text>
        </Box>

        {currenciesWithBalance[index].balance && (
          <Text className="balance" ml="auto">
            {Number(currenciesWithBalance[index].balance.toEther().toFixed(6)) > 0
              ? Number(currenciesWithBalance[index].balance.toEther().toFixed(6))
              : ''}
          </Text>
        )}
      </ListItem>
    </div>
  )
  return (
    <Modal hideCloseButton={false} onDismiss={handleDismiss} title={t('Select a token')} minWidth="500px">
      <Box borderRadius={'12px'} background={theme.colors.backgroundAlt} p="4%" mt="1rem">
        {loading ? (
          <Box py={'9.6px'}>
            <ShimmerCard
              height={43.5}
              gap={9.6}
              totalCards={6}
              backgroundColor="#00000059"
              foregroundColor="#BDC2C420"
            />
          </Box>
        ) : (
          <>
            {currenciesWithBalance && currenciesWithBalance.length > 0 ? (
              <List height={380} width="100%" itemSize={55} itemCount={currenciesWithBalance.length}>
                {Row}
              </List>
            ) : (
              <Flex alignItems={'center'} justifyContent="center" minHeight={'200px'}>
                <Text fontFamily={theme.fonts.primary}>{t('0 search results found')}</Text>
              </Flex>
            )}
          </>
        )}
      </Box>
    </Modal>
  )
}

export default TokensList
