import { Flex } from 'components/Box'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'contexts/Localization'
import { useModal } from 'widgets/Modal'
import { Field } from 'state/types'
import useViewport from 'hooks/useViewport'
import { ChainWrapper, IoIosArrowDownIcon } from 'views/Swap/styles'
import { TokenWrapper } from 'views/Swap/Default/components/DestinationTokenInfo/style'
import TokensList from '../../modals/tokenList'
import BlockchainNetworkFC from 'views/Swap/Default/components/BlockchainNetwork'
import { useCurrency, useChainInfo, useGetBlockchainInfo } from 'state/stableSwap/selectors'

import { CurrencyAmount } from 'config/entities'
import useFetchCurrencyBalance from 'hooks/useFetchCurrencyBalance'
import { useWalletState } from 'state/wallets/hooks'
import { DotLoader } from 'components/Svg'
import Image from 'components/Image'
import WalletModalStable from 'components/WalletModalStable'

const DestinationToken: React.FC = () => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const IsMobile = width <= 990
  const { t } = useTranslation()
  const [currencyBalance, setCurrencyBalance] = useState<CurrencyAmount>(new CurrencyAmount(0))
  const { fetchCurrencyBalance } = useFetchCurrencyBalance()
  const [balanceLoading, setBalanceLoading] = useState(false)

  const walletInfo = useWalletState(Field.OUTPUT)
  const selectedToken = useCurrency(Field.OUTPUT)
  const outputChainId = useChainInfo(Field.OUTPUT)

  //Modals
  const [onPresentCallback, onDismiss] = useModal(
    <TokensList handleDismiss={() => onDismiss()} field={Field.OUTPUT} />,
    true,
  )
  const [showWalletModal, onWalletDismiss] = useModal(
    <WalletModalStable handleDismiss={() => onWalletDismiss()} field={Field.OUTPUT} isForConfirmation={false} />,
    true,
  )

  const outputBlockchainInfo = useGetBlockchainInfo(Field.OUTPUT)

  const fetchBalance = async () => {
    setBalanceLoading(true)
    const _token = {
      chainId: outputChainId,
      address: selectedToken?.tokenAddress,
      decimals: selectedToken?.decimals,
      symbol: selectedToken?.symbol,
      name: selectedToken?.name,
      balance: new CurrencyAmount(0),
      logoURI: '',
    }
    const inputCurrencyWithBalance = await fetchCurrencyBalance(outputChainId, _token)
    setCurrencyBalance(inputCurrencyWithBalance?.balance)
    setBalanceLoading(false)
  }

  useEffect(() => {
    walletInfo && selectedToken && fetchBalance()
  }, [selectedToken, walletInfo])

  return (
    <>
      <Flex justifyContent="space-between" mt="7px">
        <Text
          mt="auto"
          fontSize={IsMobile ? '11px' : '16px'}
          fontFamily={theme.fonts.secondary}
          fontWeight={theme.fonts.regular}
          color={theme.colors.textBlur}
        >
          {t('To Chain')}
        </Text>
        {walletInfo &&
          (balanceLoading ? (
            <DotLoader />
          ) : (
            <Text
              fontSize="16px"
              color={theme.colors.textHeading}
              mt="auto"
              fontFamily={theme.fonts.secondary}
              fontWeight={theme.fonts.regular}
            >
              {t('Balance')}: {currencyBalance ? Number(currencyBalance.toEther().toFixed(3)) : '0'}
            </Text>
          ))}
      </Flex>
      <Flex mt="8px" justifyContent="space-between">
        <ChainWrapper bgColor={theme.colors.backgroundAlt} onClick={showWalletModal} className="fade-on-hover">
          <BlockchainNetworkFC blockchainNetwork={outputBlockchainInfo} />
          <IoIosArrowDownIcon className={'icon'} />
        </ChainWrapper>
        {selectedToken ? (
          <TokenWrapper onClick={onPresentCallback} className="fade-on-hover">
            <Flex alignItems="center">
              <Image src={`./images/tokens/${selectedToken?.symbol}.svg`} width="23px" classes="rounded-circle" />
              <Text
                fontSize="14px"
                color={theme.colors.textSubtle}
                ml="7px"
                mr={'1rem'}
                fontFamily={theme.fonts.secondary}
                fontWeight={theme.fonts.regular}
              >
                {selectedToken?.symbol}
              </Text>
            </Flex>

            <IoIosArrowDownIcon className={'icon'} />
          </TokenWrapper>
        ) : (
          <TokenWrapper className="fade-on-hover">🚫</TokenWrapper>
        )}
      </Flex>
    </>
  )
}

export default DestinationToken
