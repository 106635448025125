import { Flex } from 'components/Box'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import React, { useEffect, useState } from 'react'
import { TokenWrapper } from './style'
import { useTranslation } from 'contexts/Localization'
import { useModal } from 'widgets/Modal'
import TokensListModal from '../TokensListModal'
import WalletModal from 'components/WalletModal'
import { Field } from 'state/types'
import BlockchainNetworkFC from '../BlockchainNetwork'
import Image from 'components/Image'
import { useWalletState } from 'state/wallets/hooks'
import { DotLoader } from 'components/Svg'
import useViewport from 'hooks/useViewport'
import { ChainWrapper, IoIosArrowDownIcon } from 'views/Swap/styles'
import useFetchCurrencyBalance from 'hooks/useFetchCurrencyBalance'
import { useChainInfo, useCurrency, useSwapState, useUpdateBalance } from 'state/swap/selectors'
import { SWAP_STATES } from 'state/swap/types'
import { useGetBlockchainInfo } from 'state/swap/selectors'

const DestinationTokenInfo: React.FC = () => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const IsMobile = width <= 990

  const [onPresentCallback, onDismiss] = useModal(
    <TokensListModal handleDismiss={() => onDismiss()} field={Field.OUTPUT} />,
    true,
  )
  const [showWalletModal, onWalletDismiss] = useModal(
    <WalletModal handleDismiss={() => onWalletDismiss()} field={Field.OUTPUT} isForConfirmation={false} />,
    true,
  )
  const { t } = useTranslation()
  const walletInfo = useWalletState(Field.OUTPUT)

  const outputCurrencyWithoutBalance = useCurrency(Field.OUTPUT)

  const { fetchCurrencyBalance } = useFetchCurrencyBalance()
  const [outputCurrency, setOutputCurrency] = useState(outputCurrencyWithoutBalance)
  const [balanceLoading, setBalanceLoading] = useState(false)
  const chainID = useChainInfo(Field.OUTPUT)
  const pageState = useSwapState()

  const outputBlockchainInfo = useGetBlockchainInfo(Field.OUTPUT)

  const fetchBalance = async () => {
    setBalanceLoading(true)
    const outputCurrencyWithBalance = await fetchCurrencyBalance(chainID, outputCurrencyWithoutBalance)
    setOutputCurrency(outputCurrencyWithBalance)
    setBalanceLoading(false)
  }
  const shouldUpdateBalance = useUpdateBalance()

  useEffect(() => {
    if (pageState === SWAP_STATES.DEFAULT) fetchBalance()
  }, [outputCurrencyWithoutBalance, walletInfo, shouldUpdateBalance])

  useEffect(() => {
    if (pageState === SWAP_STATES.ROUTES_LOADING) fetchBalance()
  }, [pageState])

  return (
    <>
      <Flex justifyContent="space-between" mt="7px">
        <Text
          mt="auto"
          fontSize={IsMobile ? '11px' : '16px'}
          fontFamily={theme.fonts.secondary}
          fontWeight={theme.fonts.regular}
          color={theme.colors.textBlur}
        >
          {t('To Chain')}
        </Text>
        {walletInfo &&
          (balanceLoading ? (
            <DotLoader />
          ) : (
            <Text
              fontSize="16px"
              color={theme.colors.textHeading}
              mt="auto"
              fontFamily={theme.fonts.secondary}
              fontWeight={theme.fonts.regular}
            >
              {t('Balance')}: {outputCurrency.balance ? Number(outputCurrency.balance.toEther().toFixed(3)) : '0'}
            </Text>
          ))}
      </Flex>
      <Flex mt="8px" justifyContent="space-between">
        <ChainWrapper bgColor={theme.colors.backgroundAlt} onClick={showWalletModal} className="fade-on-hover">
          <BlockchainNetworkFC blockchainNetwork={outputBlockchainInfo} />
          <IoIosArrowDownIcon className={'icon'} />
        </ChainWrapper>
        <TokenWrapper onClick={onPresentCallback} className="fade-on-hover">
          <Flex alignItems="center">
            <Image src={outputCurrency.logoURI} width="23px" classes="rounded-circle" />
            <Text
              fontSize="14px"
              color={theme.colors.textSubtle}
              ml="7px"
              mr={'1rem'}
              fontFamily={theme.fonts.secondary}
              fontWeight={theme.fonts.regular}
            >
              {outputCurrency.symbol}
            </Text>
          </Flex>

          <IoIosArrowDownIcon className={'icon'} />
        </TokenWrapper>
      </Flex>
    </>
  )
}

export default DestinationTokenInfo
