import React, { useEffect } from 'react'
import styled from 'styled-components'
import useCurrentTime from 'hooks/useTimer'
import { Flex } from 'components/Box'
import { SpinnerProgress } from 'components/Svg'
import { useRoutes, useSwapState } from 'state/swap/selectors'
import { SWAP_STATES } from 'state/swap/types'

interface TimerProps {
  setIsRefresh: any
  isRefresh: boolean
}

const TimerComponent: React.FC<TimerProps> = (props: TimerProps) => {
  const { setIsRefresh, isRefresh } = props
  const { refreshTime, startTimer, stopTimer } = useCurrentTime(20)
  const pageState = useSwapState()

  const { routes } = useRoutes()
  useEffect(() => {
    if (refreshTime === 0) {
      if (pageState === SWAP_STATES.DEFAULT) setIsRefresh(!isRefresh)
      startTimer(20)
    }
  }, [refreshTime])

  useEffect(() => {
    if (pageState === SWAP_STATES.ROUTES_LOADING || pageState === SWAP_STATES.NO_ROUTES) {
      stopTimer(20)
    } else {
      startTimer(20)
    }
  }, [pageState])

  if (routes.length <= 0) return null

  return (
    <TimerWrapper>
      <Flex
        alignItems="center"
        role="button"
        onClick={() => {
          setIsRefresh(!isRefresh)
          startTimer()
        }}
        style={{ opacity: pageState === SWAP_STATES.ROUTES_LOADING ? 0.4 : 1 }}
      >
        <SpinnerProgress width={'18'} height={'18'} />
      </Flex>
    </TimerWrapper>
  )
}

const TimerWrapper = styled.div`
  .icon {
    font-size: 20px;
  }

  @media screen and (max-width: 1240px), (max-height: 700px) {
    .icon {
      font-size: 15px;
    }
  }
`

export default TimerComponent
