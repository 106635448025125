import { useCallback } from 'react'
import * as Sentry from '@sentry/react'

import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getAtlasDexSwapAddress, getWormholeTokenBridgeAddress } from 'utils/addressHelpers'
import { useAppDispatch } from 'state'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { useEVMSwapData } from './useEVMSwapData'
import { IRoute, IActionStepState, ACTION_STEP_STATE } from 'state/swap/types'
import { getAtlasDexSwapContract } from 'utils/contractHelpers'
import { calculateGasMargin, getProviderOrSigner } from 'utils'
import { TransactionResponse } from '@ethersproject/providers'
import { getTransactionReceipt } from 'utils/callHelper'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { SupportedChainId } from 'config/constants/chains'

export const useAtlasDexSwapUnlock = () => {
  const dispatch = useAppDispatch()
  const { account, library } = useActiveWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { handleEVMSwapData } = useEVMSwapData()
  const { onSetStepResult } = useSwapActionHandlers()

  const handleSwapUnlockEVM = useCallback(
    async (routeInfo: IRoute, prevStepResult): Promise<IActionStepState> => {
      try {
        const atlasDexAddress = getAtlasDexSwapAddress(routeInfo.outputMintCurrency.chainId)
        const wormholeTokenBridgeAddress = getWormholeTokenBridgeAddress(routeInfo.outputMintCurrency.chainId)
        const atlasDexSwapContract = getAtlasDexSwapContract(atlasDexAddress, getProviderOrSigner(library, account))
        const actionStepState = await handleEVMSwapData({
          ...routeInfo,
          inputCurrency: routeInfo.outputMintCurrency,
          sourceInputAmount: prevStepResult.lockedAmount,
        })
        if (actionStepState.status !== ACTION_STEP_STATE.OK) {
          throw new Error(actionStepState.message)
        } // end of if for action not ok

        const vaa = `0x${prevStepResult.vaa}`
        const overrides = {
          // The amount to send with the transaction (i.e. msg.value)
          value: actionStepState.payload.value,
        }

        const estimatedGas = await atlasDexSwapContract.estimateGas.unlockTokens(
          wormholeTokenBridgeAddress,
          vaa,
          actionStepState.payload._1inchData,
          actionStepState.payload._0xData,
          actionStepState.payload._IsWrapped,
          actionStepState.payload._IsUnWrapped,
          actionStepState.payload._amount,

          overrides,
        )
        const gasPrice = await library.getGasPrice()
        let enhancedPrice = calculateGasMargin(gasPrice)

        if (
          routeInfo.outputCurrency.chainId === SupportedChainId.POLYGON ||
          routeInfo.outputCurrency.chainId === SupportedChainId.FANTOM
        ) {
          enhancedPrice = calculateGasMargin(gasPrice.mul(2))
        }

        const response: TransactionResponse = await callWithGasPrice(
          atlasDexSwapContract,
          'unlockTokens',
          [
            wormholeTokenBridgeAddress,
            vaa,
            actionStepState.payload._1inchData,
            actionStepState.payload._0xData,
            actionStepState.payload._IsWrapped,
            actionStepState.payload._IsUnWrapped,
            actionStepState.payload._amount,
          ],
          {
            gasLimit: calculateGasMargin(estimatedGas),
            value: actionStepState.payload.value,
            gasPrice: enhancedPrice,
          },
        )
        onSetStepResult({
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: 'IN-Progress',
          payload: {
            txid: response.hash,
          },
        })
        //:TODO implement later getting tx if dropped.
        const receipt = await getTransactionReceipt(
          library,
          response.hash,
          routeInfo.outputMintCurrency.chainId,
          account,
        )

        return {
          status: ACTION_STEP_STATE.OK,
          message: '',
          payload: {
            receipt,
            txid: receipt.transactionHash,
          },
        }
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [dispatch, library, account],
  )

  return { onAtlasDexSwapUnlock: handleSwapUnlockEVM }
}
