import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { IBlockchainUtils } from 'state/blockchain/types'

/**
 * Returns Wallet of selected Chain
 * @param chainId
 */
export function useGetBlockchainUtils(chainId: number): IBlockchainUtils {
  const state = useSelector<AppState, AppState['blockchain']>((s) => s.blockchain)
  return state.blockChainUtils.find((chainUtils) => chainUtils.chainId === chainId)
}
