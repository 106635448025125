import { Box, Flex } from 'components/Box'
import Image from 'components/Image'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { useState, useEffect } from 'react'
import { FromTokenWrapper, IoIosArrowDownIcon, MaxText } from 'views/Swap/styles'
import { useModal } from 'widgets/Modal'
import { Field } from 'state/types'
import useCurrenciesWithBalance from 'hooks/useCurrencyBalance'
import TokensListModal from '../TokensListModal'
import { useAutomaticOnTargetChain, useRoutes, useSteps } from 'state/swap/selectors'
import { isNativeCurrency } from 'utils'
import useMinimumFee from 'hooks/useMinimumFee'
import { useWalletState } from 'state/wallets/hooks'
import { useTranslation } from 'contexts/Localization'

const PriceInput = ({ isRefresh, setSwapDisabled }) => {
  const [value, setValue] = useState('')
  const { theme } = useTheme()
  const { onUserInput, onSetEmptyRoutes } = useSwapActionHandlers()
  const { currencyWithBalance: inputCurrency } = useCurrenciesWithBalance(Field.INPUT)
  const { currencyWithBalance: outputCurrency } = useCurrenciesWithBalance(Field.OUTPUT)
  const { handleMinReqFee } = useMinimumFee()
  const fromWalletInfo = useWalletState(Field.INPUT)
  const { routes } = useRoutes()
  const isAutomaticOnTargetChain = useAutomaticOnTargetChain()
  const { t } = useTranslation()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (Number(value) === 0) {
        onUserInput(Field.OUTPUT, value.toString())
        return onSetEmptyRoutes()
      }
      onUserInput(Field.OUTPUT, value.toString())
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [value, inputCurrency, outputCurrency, isRefresh, isAutomaticOnTargetChain])

  useEffect(() => {
    if (routes.length > 0) setSwapDisabled(false)
    else setSwapDisabled(true)
  }, [routes])

  const handleChange = (event) => {
    const onlyNumbers = /^[0-9]*[.]?[0-9]*$/
    if (!onlyNumbers.test(event.target.value)) return
    setValue(event.target.value)
  }

  // // For reseting value
  const { steps } = useSteps()
  useEffect(() => {
    steps.length === 0 && value !== '' && setValue('')
  }, [steps])

  const [onPresentCallback, onDismiss] = useModal(
    <TokensListModal handleDismiss={() => onDismiss()} field={Field.INPUT} />,
    true,
  )

  return (
    <FromTokenWrapper>
      <input type="text" placeholder="0" value={value} onChange={handleChange} />
      <Flex className="pointer" alignItems="center">
        <MaxText
          onClick={async () => {
            if (isNativeCurrency(inputCurrency)) {
              const inputFee = await handleMinReqFee(fromWalletInfo?.chainId)
              if (inputFee.hasRequiredBalance) {
                setValue(
                  inputCurrency?.balance?.toEther().minus(inputFee?.network.minimumFee.toEther()).toString() || '0',
                )
              } else {
                setValue('0')
              }
            } else {
              setValue(inputCurrency?.balance?.toEther().toString() || '0')
            }
          }}
        >
          {t('MAX')}
        </MaxText>
        <Box className="line" />
        <Flex minWidth="92px" alignItems={'center'} onClick={onPresentCallback}>
          <Image src={inputCurrency.logoURI} width="23px" classes="rounded-circle" />
          <Text
            ml="7px"
            fontSize="14px"
            color={theme.colors.text}
            fontFamily={theme.fonts.secondary}
            fontWeight={theme.fonts.regular}
          >
            {inputCurrency.symbol}
          </Text>
          <IoIosArrowDownIcon className={'icon'} />
        </Flex>
      </Flex>
    </FromTokenWrapper>
  )
}

export default PriceInput
