import { Flex, Box } from 'components/Box'
import styled from 'styled-components'

export const TokenTag = styled(Flex)`
  background: #2f2f42;
  border-radius: 12px;
  padding: 5px 9px;
  margin: 16px 12px 0 0;
  cursor: pointer;
  &.disable {
    opacity: 0.3;
  }
  .symbol {
    margin: 0;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    margin-left: 0.4rem;
  }

  .token-icon {
    width: 24px;
    height: 24px;
  }

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    margin: 16px 6px 0 0;
    .symbol {
      font-size: small;
    }
    .token-icon {
      width: 20px;
      height: 20px;
    }
  }
`

export const Input = styled('input')`
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.backgroundSubtle}50;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.light};
  padding: 0.4rem 1rem;
  border-radius: 12px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0.2rem 0.8rem;
  }
`

export const List = styled(Box)`
  overflow-y: scroll;
  max-height: 340px;
  margin-top: 0.4rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.secondary};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }
`

export const ListItem = styled(Flex)`
  margin: 0.6rem 0;
  cursor: pointer;
  align-items: center;
  width: 100%;
  &:hover {
    opacity: 0.9;
  }
  &.disable {
    opacity: 0.3;
  }
  .balance {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.regular};
    margin-right: 0.4rem;
  }
  .token-symbol {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.semiBold};
    font-size: medium;
    margin: 0;
  }
  .wallet-name {
    color: ${({ theme }) => theme.colors.textHeading};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fonts.light};
    font-size: small;
    margin: 0;
  }

  .symbol-icon {
    width: 40px;
    height: 40px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 0.2rem 0;
    .balance {
      font-size: small;
    }
    .token-symbol {
      font-size: small;
    }
    .wallet-name {
      font-size: x-small;
    }
    .symbol-icon {
      width: 24px;
      height: 24px;
    }
  }
`
