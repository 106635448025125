import Card from 'components/Card'
import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import { ChainWrapper, IoIosArrowDownIcon, SettingIcon } from '../styles'
import BlockchainNetworkFC from '../Default/components/BlockchainNetwork'
import { Field } from 'state/types'
import { DotLoader, RepeatIcon } from 'components/Svg'
import Button from 'components/Button'
import { SWAP_STATES } from 'state/swap/types'
import React, { useEffect, useState } from 'react'
import useViewport from 'hooks/useViewport'
import useTheme from 'hooks/useTheme'
import { useWalletActionHandlers, useWalletState } from 'state/wallets/hooks'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { useChainInfo } from 'state/stableSwap/selectors'
import { useModal } from 'widgets/Modal'
import SettingsPopup from '../Default/components/SettingPopup'
import { useTranslation } from 'contexts/Localization'
import useFetchCurrencyBalance from 'hooks/useFetchCurrencyBalance'
import InputField from './components/InputField'
import DestinationToken from './components/DestinationToken'
import { DestinationWrapper } from './style'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'
import { useInputAmount, useReceivedAmount, useCurrency, useStableSwapState } from 'state/stableSwap/selectors'
import QuoteBox from './components/QuoteBox'
import { CurrencyAmount } from 'config/entities'
import { SWAP_TAB } from 'state/global/types'
import { STABLE_CHAINS_SET } from 'config/constants/chains'
import { useSwapSelectedTab } from 'state/global/selectors'
import { useGetBlockchainInfo } from 'state/stableSwap/selectors'
import WalletModalStable from 'components/WalletModalStable'
import useStableSwap from './hooks/useStableSwap'

const StableSwap = () => {
  useStableSwap()
  const { width } = useViewport()
  const { theme } = useTheme()
  const IsMobile = width <= 990
  const { t } = useTranslation()
  const { fetchCurrencyBalance } = useFetchCurrencyBalance()
  const [inputCurrency, setInputCurrency] = useState<CurrencyAmount>(new CurrencyAmount(0))
  const [balanceLoading, setBalanceLoading] = useState(false)
  const inputChainId = useChainInfo(Field.INPUT)
  const outputChainId = useChainInfo(Field.OUTPUT)
  const pageState = useStableSwapState()
  const selectedTab = useSwapSelectedTab()

  const inputToken = useCurrency(Field.INPUT)
  const outputToken = useCurrency(Field.OUTPUT)
  const enteredAmount = useInputAmount()
  const receiveAmount = useReceivedAmount()

  const { onSwitchCurrencies, onUpdateSwapPageState } = useStableSwapActionHandlers()
  const { onSwitchWallets } = useWalletActionHandlers()
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const walletInfo = useWalletState(Field.INPUT)

  const [showWalletModal, onWalletDismiss] = useModal(
    <WalletModalStable
      handleDismiss={() => {
        onWalletDismiss()
      }}
      field={Field.INPUT}
      isForConfirmation={false}
    />,
    true,
  )
  const [onPresentCallbackSettings, onDismissSettings] = useModal(
    <SettingsPopup handleDismiss={() => onDismissSettings()} />,
    Number(userSlippageTolerance.toFixed()) >= 0.5 && Number(userSlippageTolerance.toFixed()) <= 50,
  )

  const [showWalletConfirmationModal, onWalletConfirmationDismiss] = useModal(
    <WalletModalStable
      handleDismiss={() => {
        // onUpdateSwapPageState(SWAP_STATES.DEFAULT)
        onWalletConfirmationDismiss()
      }}
      field={Field.INPUT}
      isForConfirmation={true}
    />,
    true,
  )

  const fetchBalance = async () => {
    setBalanceLoading(true)
    const _token = {
      chainId: inputChainId,
      address: inputToken?.tokenAddress,
      decimals: inputToken?.decimals,
      symbol: inputToken?.symbol,
      name: inputToken?.name,
      balance: new CurrencyAmount(0),
      logoURI: '',
    }
    const inputCurrencyWithBalance = await fetchCurrencyBalance(inputChainId, _token)
    setInputCurrency(inputCurrencyWithBalance?.balance)
    setBalanceLoading(false)
  }

  useEffect(() => {
    walletInfo && inputToken && fetchBalance()
  }, [inputToken, walletInfo])

  useEffect(() => {
    if (pageState === SWAP_STATES.ROUTES_LOADING) fetchBalance()
  }, [pageState])

  const isDisableSwap =
    selectedTab === SWAP_TAB.STABLE && (!STABLE_CHAINS_SET.has(inputChainId) || !STABLE_CHAINS_SET.has(outputChainId))

  const [swapButtonText, setSwapButtonText] = useState(t('Swap'))

  useEffect(() => {
    if (isDisableSwap) {
      setSwapButtonText(t('Bridge not supported yet'))
    } else if (pageState === SWAP_STATES.ROUTES_LOADING) {
      setSwapButtonText(t('Loading'))
    } else {
      setSwapButtonText('Swap')
    }
  }, [pageState])

  const inputBlockchainInfo = useGetBlockchainInfo(Field.INPUT)

  return (
    <Card width={width > 500 ? '481px' : '94%'}>
      <Flex justifyContent="space-between">
        <Text
          fontSize={IsMobile ? '18px' : '24px'}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.semiBold}
          color={theme.colors.text}
        >
          {t('Stable Swap')}
        </Text>
        <Flex ml="auto" justifyContent="center" alignItems="center">
          <SettingIcon className="scale-on-hover" onClick={onPresentCallbackSettings} />
        </Flex>
      </Flex>
      <Box mt="14px" mx="6px">
        <Text
          fontSize={IsMobile ? '11px' : '16px'}
          fontFamily={theme.fonts.secondary}
          fontWeight={theme.fonts.regular}
          color={theme.colors.textBlur}
        >
          {t('From Chain')}
        </Text>
        <Flex justifyContent="space-between" mt="7px">
          <ChainWrapper
            bgColor={`${theme.colors.backgroundSubtle}47`}
            onClick={showWalletModal}
            className="fade-on-hover"
          >
            <BlockchainNetworkFC blockchainNetwork={inputBlockchainInfo} />
            <IoIosArrowDownIcon className={'icon'} />
          </ChainWrapper>
          {walletInfo &&
            (balanceLoading ? (
              <DotLoader />
            ) : (
              inputCurrency && (
                <Text
                  fontSize="16px"
                  color={theme.colors.textHeading}
                  mt="auto"
                  fontFamily={theme.fonts.secondary}
                  fontWeight={theme.fonts.regular}
                >
                  {t('Balance')}: {inputCurrency ? Number(inputCurrency.toEther().toFixed(3)) : '0'}
                </Text>
              )
            ))}
        </Flex>
        <InputField balance={inputCurrency} />
      </Box>
      <Flex mt="0.5rem" alignItems="center" justifyContent={'center'} role="button">
        <RepeatIcon
          onClick={() => {
            onSwitchCurrencies()
            onSwitchWallets()
          }}
          className="scale-on-hover"
        />
      </Flex>
      <DestinationWrapper>
        <DestinationToken />
        {/* To show receive amount */}
        <QuoteBox isDisableSwap={isDisableSwap} />
      </DestinationWrapper>

      <Button
        borderRadius="8px"
        padding="0.6rem"
        marginTop={'1.2rem'}
        width={'100%'}
        onClick={async () => {
          // try {
          //   const response = await handleStableSwap(inputToken, outputToken, enteredAmount)
          //   console.info('Stable Swap response', response)
          // } catch (error) {
          //   console.error(error)
          // }
          onUpdateSwapPageState(SWAP_STATES.BEFORE_SWAP_WALLET_CONFIRMATIONS)
          showWalletConfirmationModal()
        }}
        disabled={isDisableSwap || receiveAmount.toEther().toNumber() <= 0}
      >
        <Text
          fontSize={IsMobile ? '12px' : '15px'}
          color={theme.colors.text}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.medium}
        >
          {swapButtonText}
        </Text>
      </Button>
    </Card>
  )
}

export default StableSwap
