import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import { useState, useEffect } from 'react'
import { FromTokenWrapper, IoIosArrowDownIcon, MaxText } from 'views/Swap/styles'
import { useModal } from 'widgets/Modal'
import { Field } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import TokensList from '../../modals/tokenList'
import { useCurrency } from 'state/stableSwap/selectors'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'
import Image from 'components/Image'
import { CurrencyAmount } from 'config/entities/fractions'

const InputField: React.FC<{ balance: CurrencyAmount }> = ({ balance }) => {
  const [value, setValue] = useState('')
  const { theme } = useTheme()
  const inputCurrency = useCurrency(Field.INPUT)
  const outputCurrency = useCurrency(Field.OUTPUT)

  const { onUserInput, onUpdateReceiveAmount } = useStableSwapActionHandlers()
  const { t } = useTranslation()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      //check if value NAN or equal 0
      if (Number(value) === 0 || !value) {
        onUserInput(new CurrencyAmount(0, inputCurrency?.decimals || 6, false))
        return onUpdateReceiveAmount(new CurrencyAmount(0, 0, false))
      }

      onUserInput(new CurrencyAmount(value, inputCurrency?.decimals || 6, false))
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [value, inputCurrency, outputCurrency])

  const handleChange = (event) => {
    const onlyNumbers = /^[0-9]*[.]?[0-9]*$/
    if (!onlyNumbers.test(event.target.value)) return
    setValue(event.target.value)
  }

  const [onPresentCallback, onDismiss] = useModal(
    <TokensList handleDismiss={() => onDismiss()} field={Field.INPUT} />,
    true,
  )

  return (
    <FromTokenWrapper>
      <input type="text" placeholder="0" value={value} onChange={handleChange} />
      <Flex className="pointer" alignItems="center">
        <MaxText
          onClick={async () => {
            const _balance = Math.trunc(parseFloat(balance.toEther().toString()) * 100000) / 100000
            setValue(_balance.toString() || '0')
          }}
        >
          {t('MAX')}
        </MaxText>
        <Box className="line" />
        {inputCurrency ? (
          <Flex minWidth="92px" alignItems={'center'} onClick={onPresentCallback}>
            <Image src={`./images/tokens/${inputCurrency?.symbol}.svg`} width="23px" classes="rounded-circle" />
            <Text
              ml="7px"
              fontSize="14px"
              color={theme.colors.text}
              fontFamily={theme.fonts.secondary}
              fontWeight={theme.fonts.regular}
            >
              {inputCurrency?.symbol}
            </Text>
            <IoIosArrowDownIcon className={'icon'} />
          </Flex>
        ) : (
          <Flex width={'100%'} alignItems={'center'}>
            🚫
          </Flex>
        )}
      </Flex>
    </FromTokenWrapper>
  )
}

export default InputField
