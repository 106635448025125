import { useEffect, useState } from 'react'
import { getMulticallAddress } from 'utils/addressHelpers'
import erc20Abi from 'config/abi/erc20.json'
import { multicallv2 } from 'utils/multicall'
import { CurrencyAmount } from 'config/entities/fractions/CurrencyAmount'
import useActiveWeb3React from './useActiveWeb3React'
import { SupportedEVMChainId } from 'config/constants/chains'
import { ethers } from 'ethers'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import { Currency } from 'config/entities'
import * as Sentry from '@sentry/react'

import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { useWallet } from '@solana/wallet-adapter-react'
import { useConnection } from '@solana/wallet-adapter-react'
import { Field } from 'state/types'
import { useCurrencies, useChainInfo, useCurrency, useUpdateBalance } from 'state/swap/selectors'

const useCurrenciesWithBalance = (field: Field) => {
  const currencies = useCurrencies(field)
  const currency = useCurrency(field)
  const chainId = useChainInfo(field)
  const updateBalance = useUpdateBalance()
  const { account } = useActiveWeb3React()
  const { publicKey } = useWallet()
  const { connection } = useConnection()
  const [loading, setLoading] = useState(false)

  const [currenciesWithBalance, setCurrenciesWithBalance] = useState<Currency[]>(currencies)
  const [currencyWithBalance, setCurrencyWithBalance] = useState<Currency>(currency)

  function prepareMultiCall(currency, chainId) {
    if (currency.address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
      return {
        address: getMulticallAddress(chainId),
        name: 'getEthBalance',
        params: [account],
      }
    }
    return {
      address: currency.address,
      name: 'balanceOf',
      params: [account],
    }
  }

  async function handleMultiCalls(currencies, chainId, provider) {
    const balanceCalls = currencies.map((currency) => {
      return prepareMultiCall(currency, chainId)
    })
    try {
      const balanceResults = await multicallv2(erc20Abi, balanceCalls, chainId, provider)
      const currenciesWithBalance = currencies.map((currency, index) => {
        return {
          ...currency,
          balance: new CurrencyAmount(balanceResults[index], currency.decimals),
        }
      })
      return currenciesWithBalance
    } catch (error) {
      Sentry.captureException(error)
    }
    return currencies
  }

  const fetchEVMBalance = async () => {
    setLoading(true)

    const providerURL = getEVMNodeURL[chainId]
    const provider = new ethers.providers.JsonRpcProvider(providerURL)

    const _currenciesWithBalance = await handleMultiCalls(currencies, chainId, provider)
    const sortedBalanceArray = _currenciesWithBalance.sort((a, b) => b.balance.toEther() - a.balance.toEther())
    const _currencyWithBalance = sortedBalanceArray.find(
      (balancedCurrency) => balancedCurrency.address === currency.address,
    )
    _currencyWithBalance && setCurrencyWithBalance(_currencyWithBalance)
    setCurrenciesWithBalance(sortedBalanceArray)
    setLoading(false)
  }

  const fetchSolBalance = async () => {
    try {
      setLoading(true)
      const parsedTokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
        programId: TOKEN_PROGRAM_ID,
      })
      const solBalance = await connection.getBalance(publicKey)
      const currenciesWithBalance = currencies.map((currency, index) => {
        if (index === 0) {
          setLoading(false)
          return {
            ...currency,
            balance: new CurrencyAmount(solBalance, currencies[0].decimals),
          }
        } else {
          const accountInfo = parsedTokenAccounts.value.find((account) => {
            return account.account.data.parsed.info.mint === currency.address
          })
          const tokenAmount = accountInfo
            ? accountInfo.account.data.parsed.info.tokenAmount
            : {
                amount: 0,
                decimals: currency.decimals,
              }
          setLoading(false)
          return {
            ...currency,
            balance: new CurrencyAmount(tokenAmount.amount, tokenAmount.decimals),
          }
        }
      })
      const sortedBalanceArray = currenciesWithBalance.sort(
        (a, b) => Number(b.balance.toEther()) - Number(a.balance.toEther()),
      )
      setCurrenciesWithBalance(sortedBalanceArray)
      const _currencyWithBalance = sortedBalanceArray.find(
        (balancedCurrency) => balancedCurrency.address === currency.address,
      )
      _currencyWithBalance && setCurrencyWithBalance(_currencyWithBalance)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    setCurrencyWithBalance(currency)
    if (SupportedEVMChainId(chainId)) {
      ethers.utils.isAddress(account) && fetchEVMBalance()
    } else {
      publicKey && fetchSolBalance()
    }
  }, [connection, publicKey, account, currencies, currency, updateBalance])

  return { currenciesWithBalance, currencyWithBalance, loading }
}

export default useCurrenciesWithBalance
