import { Form } from 'react-bootstrap'
import { Modal } from 'widgets/Modal'
import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { useState } from 'react'
import { Percent } from 'config/entities'
import JSBI from 'jsbi'
import Button from 'components/Button'
import { BIPS_BASE } from 'config/constants'
import { useTranslation } from 'contexts/Localization'

function SettingsPopup({ handleDismiss }) {
  const { theme } = useTheme()
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const { t } = useTranslation()
  const [slippage, setSlippage] = useState<string>(userSlippageTolerance.toFixed(2))

  const handleSubmit = () => {
    if (Number(slippage) > 50 || Number(slippage) < 0.5) return
    setUserSlippageTolerance(new Percent(JSBI.BigInt(Number(Number(slippage).toFixed(2)) * 100), BIPS_BASE))
    handleDismiss()
  }

  return (
    <Modal hideCloseButton={false} onDismiss={handleDismiss} title={t('Settings')} minWidth="500px">
      <Box mt="8%">
        <Text fontFamily={theme.fonts.primary} fontWeight={theme.fonts.semiBold} fontSize="16px" marginBottom="12px">
          {t('Slippage tolerance')}
        </Text>
      </Box>

      <Flex className="row" mt="0%">
        <Flex justifyContent="center" alignItems="center" className="col-8">
          <Form.Range min={0.5} max={2} step={0.01} value={slippage} onChange={(e) => setSlippage(e.target.value)} />
        </Flex>
        <Flex className="col-4">
          <InputField
            placeholder="Custom %"
            type="text"
            value={slippage}
            onChange={(e) => {
              const onlyNumbers = /^[0-9]*[.]?[0-9]*$/
              if (!onlyNumbers.test(e.target.value)) return
              setSlippage(e.target.value)
            }}
          />
        </Flex>
      </Flex>

      <Flex className="row" mt="-6px" mb="2rem">
        <Flex justifyContent="space-between" alignItems="center" className="col-8" p="0 12px">
          <Text
            fontFamily={theme.fonts.secondary}
            fontWeight={theme.fonts.light}
            color={theme.colors.textSubtle}
            fontSize="16px"
          >
            0.5%
          </Text>
          <Text
            fontFamily={theme.fonts.secondary}
            fontWeight={theme.fonts.light}
            color={theme.colors.textSubtle}
            fontSize="16px"
          >
            1%
          </Text>
          <Text
            fontFamily={theme.fonts.secondary}
            fontWeight={theme.fonts.light}
            color={theme.colors.textSubtle}
            fontSize="16px"
          >
            1.5%
          </Text>
          <Text
            fontFamily={theme.fonts.secondary}
            fontWeight={theme.fonts.light}
            color={theme.colors.textSubtle}
            fontSize="16px"
          >
            2%
          </Text>
        </Flex>
      </Flex>
      {(Number(slippage) < 0.5 || Number(slippage) > 50) && (
        <Flex>
          <Text
            color={theme.colors.failure}
            fontSize="14px"
            fontFamily={theme.fonts.primary}
            fontWeight={theme.fonts.regular}
            mt="1rem"
          >
            {/* Slippage cannot be {Number(slippage) > 50 ? 'greater' : 'less'} than {Number(slippage) > 50 ? 50 : 0.5}% */}
            {Number(slippage) > 50
              ? t('Slippage cannot be greater than %p').replace('%p', `50%`)
              : t('Slippage cannot be less than %p').replace('%p', `0.5%`)}
          </Text>
        </Flex>
      )}

      <Flex width="100%" justifyContent="center" mt="1rem">
        <Button m="0 auto" p="0.4rem 1.8rem" borderRadius="12px" onClick={handleSubmit}>
          {t('Submit')}
        </Button>
      </Flex>
    </Modal>
  )
}

const InputField = styled('input')`
  border: none;
  outline: none;
  background: ${({ theme }) => theme.colors.backgroundSubtle}47;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: ${({ theme }) => theme.fonts.light};
  padding: 0.6rem 1rem;
  border-radius: 8px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};

  &:focus {
    background: ${({ theme }) => theme.colors.backgroundSubtle}30;
  }
`

export default SettingsPopup
