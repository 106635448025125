import { Box } from 'components/Box'
import styled from 'styled-components'

export const RouteWrapper = styled(Box)`
  .inner-section {
    background: ${({ theme }) => theme.colors.backgroundAlt};
    border-radius: 12px;
    padding: 0.7rem 1rem;
    border: 2px solid transparent;
    &.select {
      border: 2px solid ${({ theme }) => theme.colors.textSubtle};
    }
  }
  .sub-text {
    width: 103px;
    background: ${({ theme }) => theme.colors.primary};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
  }
  .best-price {
    width: 81px;
    background: ${({ theme }) => theme.colors.success};
    border-bottom-left-radius: 12px;
    border-top-right-radius: 9px;
  }

  .liquidity-message {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fonts.medium};
    font-size: medium;
    color: ${({ theme }) => theme.colors.text};
  }
`
