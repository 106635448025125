import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback } from 'react'
import * as Sentry from '@sentry/react'

import { calculateGasMargin, getProviderOrSigner } from 'utils'
import { useCallWithGasPrice } from './useCallWithGasPrice'
import { Currency, CurrencyAmount } from 'config/entities'
import { formatEther } from '@ethersproject/units'
import contracts from 'config/constants/contracts'
import { getTransactionReceipt } from 'utils/callHelper'
import { ACTION_STEP_STATE, IActionStepState } from 'state/swap/types'
import { ETHER } from 'config/constants'
import { getBep20Contract } from 'utils/contractHelpers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { SupportedChainId } from '../config/constants/chains'

const useApproveCallback = () => {
  const { account, library } = useActiveWeb3React()

  const { callWithGasPrice } = useCallWithGasPrice()
  const { onSetStepResult } = useSwapActionHandlers()

  const approve = useCallback(
    async (
      amountToApprove: CurrencyAmount,
      currency: Currency,
      spender = null,
      isForSwap = true,
    ): Promise<IActionStepState> => {
      try {
        if (!amountToApprove) {
          return {
            status: ACTION_STEP_STATE.ERROR,
            message: 'Invalid Amount to approve provided.',
          }
        }
        if (currency.address === ETHER) {
          return {
            status: ACTION_STEP_STATE.OK,
            message: '',
          }
        }

        const spenderAddress = spender || contracts.atlasDexSwap[currency.chainId]
        const tokenContract = getBep20Contract(currency.address, getProviderOrSigner(library, account))
        const approvedAmountInWei = await tokenContract.allowance(account, spenderAddress)
        const currentAllowance = formatEther(approvedAmountInWei)

        if (!currentAllowance) {
          return {
            status: ACTION_STEP_STATE.ERROR,
            message: 'Invalid Current Allowance!',
          }
        }

        if (!currency) {
          return {
            status: ACTION_STEP_STATE.ERROR,
            message: 'Invalid Currency!',
          }
        }

        if (!tokenContract) {
          return {
            status: ACTION_STEP_STATE.ERROR,
            message: 'Invalid Token Contract Loaded!',
          }
        }

        if (Number(amountToApprove.toEther().toNumber()) < Number(currentAllowance)) {
          return {
            status: ACTION_STEP_STATE.OK,
            message: '',
          }
        } else {
          const useExact = false
          const estimatedGas = await tokenContract.estimateGas.approve(spenderAddress, MaxUint256).catch(() => {
            return tokenContract.estimateGas.approve(spenderAddress, amountToApprove.toWei().toFixed(0))
          })

          const gasPrice = await library.getGasPrice()
          let enhancedPrice = calculateGasMargin(gasPrice)

          if (currency.chainId === SupportedChainId.POLYGON || currency.chainId === SupportedChainId.FANTOM) {
            enhancedPrice = calculateGasMargin(gasPrice.mul(2))
          }

          const response: TransactionResponse = await callWithGasPrice(
            tokenContract,
            'approve',
            [spenderAddress, useExact ? amountToApprove.toWei().toFixed(0) : MaxUint256],
            {
              gasLimit: calculateGasMargin(estimatedGas),
              gasPrice: enhancedPrice,
            },
          )
          if (isForSwap) {
            onSetStepResult({
              status: ACTION_STEP_STATE.IN_PROGRESS,
              message: 'IN-Progress',
              payload: {
                txid: response.hash,
              },
            })
          }
          await getTransactionReceipt(library, response.hash, currency.chainId, account)
          return {
            status: ACTION_STEP_STATE.OK,
            message: '',
          }
        }
      } catch (error) {
        console.info(error)
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [callWithGasPrice, library, account],
  )

  return { approve }
}

export default useApproveCallback
