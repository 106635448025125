import { useCallback } from 'react'
import { getAtlasDexSwapAddress } from 'utils/addressHelpers'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { getTransactionReceipt } from 'utils/callHelper'
import * as Sentry from '@sentry/react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getAtlasDexSwapContract } from 'utils/contractHelpers'
import { calculateGasMargin, getProviderOrSigner } from 'utils'
import { TransactionResponse } from '@ethersproject/providers'
import { ACTION_STEP_STATE, EVMSwapData, IActionStepState } from 'state/swap/types'
import { Currency } from 'config/entities'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { SupportedChainId } from 'config/constants/chains'

export const atlasDexSwapEVM = async (atlasDexSwapContract, _1inchData) => {
  return await atlasDexSwapContract.swapTokens(_1inchData)
}

const useAtlasDexSwapEVM = () => {
  const { account, library } = useActiveWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { onSetStepResult } = useSwapActionHandlers()

  const handleEvmSwap = useCallback(
    async (swapData: EVMSwapData, inputCurrency: Currency): Promise<IActionStepState> => {
      try {
        const atlasDexAddress = getAtlasDexSwapAddress(inputCurrency.chainId)
        const atlasDexSwapContract = getAtlasDexSwapContract(atlasDexAddress, getProviderOrSigner(library, account))
        const overrides = {
          // The amount to send with the transaction (i.e. msg.value)
          value: swapData.value,
        }

        const estimatedGas = await atlasDexSwapContract.estimateGas.swapTokens(
          swapData._1inchData,
          swapData._0xData,
          swapData._IsWrapped,
          swapData._IsUnWrapped,
          swapData._amount,
          overrides,
        )
        const gasPrice = await library.getGasPrice()
        let enhancedPrice = calculateGasMargin(gasPrice)
        if (inputCurrency.chainId === SupportedChainId.POLYGON || inputCurrency.chainId === SupportedChainId.FANTOM) {
          enhancedPrice = calculateGasMargin(gasPrice.mul(2))
        }

        const response: TransactionResponse = await callWithGasPrice(
          atlasDexSwapContract,
          'swapTokens',
          [swapData._1inchData, swapData._0xData, swapData._IsWrapped, swapData._IsUnWrapped, swapData._amount],
          {
            gasLimit: calculateGasMargin(estimatedGas),
            value: swapData.value,
            gasPrice: enhancedPrice,
          },
        )
        onSetStepResult({
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: 'IN-Progress',
          payload: {
            txid: response.hash,
          },
        })
        const receipt = await getTransactionReceipt(library, response.hash, inputCurrency.chainId, account)
        return {
          status: ACTION_STEP_STATE.OK,
          message: '',
          payload: {
            txid: response.hash,
            receipt,
          },
        }
      } catch (error) {
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [library, account, callWithGasPrice],
  )

  return { handleEvmSwap }
}

export default useAtlasDexSwapEVM
