import { useState, useEffect } from 'react'

export const useCurrentTime = (second = 60) => {
  const [currentMillis, setCurrentMillis] = useState(new Date().getTime())
  const [refreshTime, setRefreshTime] = useState(second)
  const [isStart, setStartTime] = useState<boolean>(false)

  //Start Timer
  const startTimer = (second = 60) => {
    setRefreshTime(second)
    setStartTime(true)
  }
  //Stop Timer
  const stopTimer = (second = 60) => {
    setRefreshTime(second)
    setStartTime(false)
  }

  useEffect(() => {
    const tick = () => {
      setCurrentMillis((prevMillis) => prevMillis + 1000)
      if (isStart) {
        setRefreshTime((prevTime) => prevTime - 1)
      }
    }

    const timerID = setInterval(() => tick(), 1000)

    return () => clearInterval(timerID)
  }, [isStart])

  return { currentMillis, refreshTime, startTimer, stopTimer }
}

export default useCurrentTime
