import { Flex } from 'components/Box'
import { Text } from 'components/Text'
import { IBlockchainNetworks } from 'config/constants/types'
import useTheme from 'hooks/useTheme'
import useViewport from 'hooks/useViewport'
import React, { FC } from 'react'

interface IProp {
  blockchainNetwork: IBlockchainNetworks
}
const BlockchainNetworkFC: FC<IProp> = ({ blockchainNetwork }) => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const IsMobile = width <= 990 // check for mobile screen

  return (
    <>
      <Flex alignItems="center">
        {blockchainNetwork.icon}
        <Text
          fontSize={IsMobile ? '12px' : '14px'}
          color={theme.colors.text}
          ml="5px"
          textTransform="capitalize"
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.regular}
        >
          {blockchainNetwork.name}
        </Text>
      </Flex>
    </>
  )
}

export default BlockchainNetworkFC
