import { useCallback } from 'react'
import { EVMSwapData, IRoute, ROUTE_PROVIDER } from 'state/swap/types'
import { use0xSwap } from './use0xSwap'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import use1InchSwap from './use1InchSwap'
import * as Sentry from '@sentry/react'
import { ACTION_STEP_STATE, IActionStepState } from 'state/swap/types'
import { ETHER } from 'config/constants'
import { getWrappedAddress } from 'utils/addressHelpers'

// this need to fetch rates from different aggregators like 0x, 1inch etc
export const useEVMSwapData = () => {
  const { handle1InchSwap } = use1InchSwap()
  const { handle0xSwap } = use0xSwap()
  const { account, chainId } = useActiveWeb3React()
  const handleEVMSwapData = useCallback(
    async (payload: IRoute): Promise<IActionStepState> => {
      const callDataResult: EVMSwapData = {
        _1inchData: '0x00',
        _0xData: '0x00',
        value: '0',
        _IsWrapped: false,
        _IsUnWrapped: false,
        _amount: '0',
      }

      try {
        if (payload.evmRouteName === ROUTE_PROVIDER.ONE_INCH) {
          const swapData = await handle1InchSwap(
            payload.inputCurrency.chainId,
            account,
            payload.inputCurrency.address,
            payload.outputCurrency.address,
            payload.sourceInputAmount,
          )
          callDataResult._1inchData = swapData.data
          callDataResult.value = swapData.value
        } else {
          const wrappedAddress = getWrappedAddress(chainId)
          if (
            payload.inputCurrency.address.toLowerCase() === ETHER &&
            payload.outputCurrency.address.toLowerCase() === wrappedAddress.toLowerCase()
          ) {
            callDataResult._IsWrapped = true
            callDataResult.value = payload.sourceInputAmount.toWei().toString()
          } else if (
            payload.outputCurrency.address.toLowerCase() === ETHER &&
            payload.inputCurrency.address.toLowerCase() === wrappedAddress.toLowerCase()
          ) {
            callDataResult._IsUnWrapped = true
            callDataResult._amount = payload.sourceInputAmount.toWei().toString()
          } else {
            const swapData = await handle0xSwap(
              payload.inputCurrency.chainId,
              payload.inputCurrency.address,
              payload.outputCurrency.address,
              payload.sourceInputAmount,
            )
            callDataResult._0xData = swapData.data
            callDataResult.value = swapData.value
          }
        }
        return {
          status: ACTION_STEP_STATE.OK,
          message: '',
          payload: callDataResult,
        }
      } catch (error) {
        console.error(' INSIDE ERROR FOR EVM SWAP DATA', error)
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [chainId, account],
  )
  return { handleEVMSwapData }
}
