import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import React, { useEffect, useState } from 'react'
import { RouteWrapper } from './style'
import { useTranslation } from 'contexts/Localization'
import { useGetBlockchainInfo, useRoutes, useSwapState } from 'state/swap/selectors'
import { useGetBlockchainUtils } from 'state/blockchain/selectors'
import { Field } from 'state/types'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { SWAP_STATES } from 'state/swap/types'
import { ShimmerCard } from 'components/Svg'
import { formatEther } from '@ethersproject/units'

interface IRoutesInfo {
  setSwapDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const RouteInfo: React.FC<IRoutesInfo> = (props: IRoutesInfo) => {
  const { setSwapDisabled } = props
  const { theme } = useTheme()
  const { onSelectedRoute } = useSwapActionHandlers()
  const [selected, setSelected] = useState(0)
  const { routes } = useRoutes()

  useEffect(() => {
    setSelected(0)
  }, [routes])

  const { t } = useTranslation()
  const swapPageState = useSwapState()
  const inputChain = useGetBlockchainInfo(Field.INPUT)
  const outputChain = useGetBlockchainInfo(Field.OUTPUT)
  const inputChainUtils = useGetBlockchainUtils(inputChain.chainId)
  const outputChainUtils = useGetBlockchainUtils(outputChain.chainId)
  const [defaultLength, setDefaultLength] = useState(2)

  useEffect(() => {
    if (routes.length > 0) setSwapDisabled(false)
    else setSwapDisabled(true)
  }, [routes.length])
  return (
    <RouteWrapper>
      <Box mt={'14px'}>
        {swapPageState === SWAP_STATES.ROUTES_LOADING ? (
          <Box mt={'10px'} pb={'17px'}>
            <ShimmerCard
              height={68.38}
              gap={10}
              totalCards={defaultLength - 1}
              backgroundColor="#00000059"
              foregroundColor="#BDC2C420"
            />
          </Box>
        ) : (
          routes.slice(0, defaultLength).map((route, index: number) => {
            return (
              <Flex
                key={index}
                className={'inner-section pointer ' + (selected === index && 'select')}
                mt="10px"
                position="relative"
                alignItems="center"
                justifyContent="space-between"
                onClick={() => {
                  setSelected(index)
                  onSelectedRoute(route)
                }}
              >
                <Flex flexDirection="column">
                  <Text
                    fontSize="16px"
                    color={selected === index ? theme.colors.textTertiary : theme.colors.text}
                    fontFamily={theme.fonts.secondary}
                    fontWeight={theme.fonts.regular}
                  >
                    {route.name}
                  </Text>
                  <Text
                    fontSize="12px"
                    color={selected === index ? theme.colors.textTertiary : theme.colors.text}
                    fontFamily={theme.fonts.secondary}
                    fontWeight={theme.fonts.light}
                  >
                    {route?.inputCurrency.chainId === route?.outputCurrency.chainId
                      ? formatEther(inputChainUtils.gasPrice.mul(inputChainUtils.gasLimit)) +
                        ' ' +
                        inputChainUtils.symbol
                      : formatEther(inputChainUtils.gasPrice.mul(inputChainUtils.gasLimit)) +
                        ' ' +
                        inputChainUtils.symbol +
                        ' | ' +
                        formatEther(outputChainUtils.gasPrice.mul(outputChainUtils.gasLimit)) +
                        ' ' +
                        outputChainUtils.symbol}
                  </Text>
                </Flex>
                <Box>
                  <Text
                    fontSize="18px"
                    color={index === 0 ? theme.colors.success : theme.colors.text}
                    textAlign="center"
                    fontFamily={theme.fonts.primary}
                    fontWeight={theme.fonts.bold}
                  >
                    {route.destinationOutputAmount.toEther().toFixed(5)}
                  </Text>
                  {index === 0 && (
                    <Box className="best-price" position="absolute" top="0px" right="0px">
                      <Text
                        fontSize="12px"
                        color={theme.colors.dark}
                        textAlign="center"
                        fontFamily={theme.fonts.secondary}
                        fontWeight={theme.fonts.bold}
                      >
                        {t('Best Price')}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Flex>
            )
          })
        )}

        {swapPageState !== SWAP_STATES.ROUTES_LOADING && (
          <Flex justifyContent="center" mt={'17px'}>
            {routes.length > 2 && (
              <Box className="sub-text" onClick={() => setDefaultLength(defaultLength === 5 ? 2 : 5)}>
                <Text
                  fontSize="12px"
                  color={theme.colors.textSubtle}
                  textAlign="center"
                  fontFamily={theme.fonts.secondary}
                  fontWeight={theme.fonts.regular}
                >
                  {routes.length > 5
                    ? 5 - defaultLength === 0
                      ? t('Show Less')
                      : `${5 - defaultLength} ${t('more routes')}`
                    : ''}
                </Text>
              </Box>
            )}
          </Flex>
        )}
      </Box>
    </RouteWrapper>
  )
}

export default RouteInfo
