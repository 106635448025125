import styled from 'styled-components'

export const DestinationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundSubtle}36;
  border-radius: 12px;
  padding: 0.1rem 1rem 0rem 0.7rem;
  margin-top: 0.3rem;
  padding-bottom: 0.7rem;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0.6rem 0.8rem 0rem 0.6rem;
    border-radius: 4px;
  }
`
