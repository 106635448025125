import { useCallback } from 'react'
import { ACTION_STEP_STATE, IActionStepState } from 'state/swap/types'
import * as Sentry from '@sentry/react'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { hexToUint8Array, postVaaSolanaWithRetry, redeemOnSolana } from '@certusone/wormhole-sdk'
import { getWormholeBridgeAddress, getWormholeTokenBridgeAddress } from 'utils/addressHelpers'
import { SupportedChainId } from 'config/constants/chains'
import { waitForSolanaTxConfirmation } from 'utils/callHelper'

const useHandleRedeem = () => {
  const { connection } = useConnection()
  const { publicKey, signTransaction } = useWallet()

  async function solana(payerAddress: string, signedVAA: Uint8Array): Promise<IActionStepState> {
    try {
      if (!signTransaction) {
        throw new Error('signTransaction is undefined')
      }
      await postVaaSolanaWithRetry(
        connection,
        signTransaction,
        getWormholeBridgeAddress(SupportedChainId.SOLANA),
        payerAddress,
        Buffer.from(signedVAA),
        5,
      )

      const transaction = await redeemOnSolana(
        connection,
        getWormholeBridgeAddress(SupportedChainId.SOLANA),
        getWormholeTokenBridgeAddress(SupportedChainId.SOLANA),
        payerAddress,
        signedVAA,
      )
      const signed = await signTransaction(transaction)
      const transactionId = await connection.sendRawTransaction(signed.serialize())
      await waitForSolanaTxConfirmation(transactionId, 'confirmed', connection)
      return {
        status: ACTION_STEP_STATE.OK,
        message: '',
        payload: {
          transactionId: transactionId,
        },
      }
    } catch (error) {
      Sentry.captureException(error)
      return {
        status: ACTION_STEP_STATE.ERROR,
        message: error['message'],
        error: error,
      }
    }
  }

  const redeem = useCallback(
    async (prevStepResult): Promise<IActionStepState> => {
      return solana(publicKey.toBase58().toString(), hexToUint8Array(prevStepResult.vaa))
    },
    [connection, publicKey, signTransaction],
  )

  return { redeem }
}

export default useHandleRedeem
