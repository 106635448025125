import { Box, Flex } from 'components/Box'
import Card from 'components/Card'
import { Text } from 'components/Text'
import useTheme from 'hooks/useTheme'
import useViewport from 'hooks/useViewport'
import { useEffect, useState } from 'react'
import { ProgressBar, Spinner } from 'react-bootstrap'
import { IoIosArrowRoundForward } from 'react-icons/io'
import { RiCloseFill } from 'react-icons/ri'
import { Field } from 'state/types'
import { BorderBox, HistoryWrapper, IconImage } from 'views/Swap/styles'
import useTokenInfo from 'hooks/useTokenInfo'
import { CHAIN_IDS_TO_SYMBOL, CHAIN_KEYS, NETWORKS_INFO, SupportedChainId } from 'config/constants/chains'
import { TransactionStatus, TRANSACTION_STATUS } from 'config/enums/transactionStatus'
import { useSwapTransactions } from 'state/swap/selectors'
import HistoryModal from 'views/Swap/Default/components/HistoryModal'
import { useModal } from 'widgets/Modal'
import { TransactionSuccessIcon } from 'components/Svg'
import moment from 'moment'
import { useTranslation } from 'contexts/Localization'
import { useChainInfo, useStableChainInfo } from 'state/stableSwap/selectors'
import { Messenger } from 'config/allbridge'

const HistoryBox = () => {
  const { theme } = useTheme()
  const { width } = useViewport()
  const { t } = useTranslation()
  const [isShowHistory, setIsShowHistory] = useState(true)
  const { getTokenInfo } = useTokenInfo()
  const [transferTime, setTransferTime] = useState(0)
  const chainID = useChainInfo(Field.INPUT)
  const stableChainInfo = useStableChainInfo(CHAIN_KEYS[chainID])

  useEffect(() => {
    if (stableChainInfo && stableChainInfo?.transferTime) {
      // const _timeMs = stableChainInfo?.transferTime[Messenger.ALLBRIDGE]
      // const _totalTime = _timeMs.in + _timeMs.out
      setTransferTime(Math.floor(10))
    }
  }, [stableChainInfo])

  const updatedDate = (updatedTime: any) => {
    const doneTime = new Date(updatedTime)
    const diff = Math.round((new Date().getTime() - doneTime.getTime()) / 1000)
    return diff <= 60
  }

  const transactions = useSwapTransactions()
  const pendingTransactions = transactions.filter((txDetail) => {
    return (
      (txDetail.status !== TransactionStatus.DONE &&
        txDetail.status !== TransactionStatus.FAILED &&
        txDetail.status !== TransactionStatus.INITIATED &&
        txDetail.isWithRelayer) ||
      (txDetail.isWithRelayer && txDetail.status === TransactionStatus.DONE && updatedDate(txDetail.updatedAt))
    )
  })
  const remainingTransactions = pendingTransactions.filter((item) => item.status !== TransactionStatus.DONE)
  const [showHistoryModal, onHistoryDismiss] = useModal(<HistoryModal handleDismiss={() => onHistoryDismiss()} />, true)

  return (
    <HistoryWrapper width={'100%'} justifyContent={width > 1000 ? 'end' : 'center'} mt={'45px'} mr={'25px'} flex={1}>
      {pendingTransactions.length > 0 && (
        <Flex flexDirection={'column'} alignItems={'end'} justifyContent={'end'}>
          {remainingTransactions.length !== 0 && (
            <Box mb={'22px'}>
              <Card width={'178px'} padding={'8px'}>
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  onClick={() => {
                    setIsShowHistory(true)
                  }}
                >
                  <Spinner animation="border" variant="success" size="sm" />
                  <Text
                    fontSize={'16px'}
                    color={theme.colors.text}
                    fontFamily={theme.fonts.primary}
                    fontWeight={theme.fonts.semiBold}
                    ml={'10px'}
                  >
                    Pending ({remainingTransactions.length})
                  </Text>
                </Flex>
              </Card>
            </Box>
          )}

          {isShowHistory && (
            <Card width={width > 500 ? '457px' : '100%'} padding="21px 19.3px">
              {pendingTransactions.map((item, index) => {
                const fromTokenInfo = getTokenInfo(item?.fromChainId, item?.fromToken)
                const toTokenInfo = getTokenInfo(item?.toChainId, item?.toToken)
                return (
                  <BorderBox key={index} mt={index !== 0 && '15px'} isBorder={index !== 0}>
                    <Flex mt={index !== 0 && '15px'} justifyContent={'space-between'} alignItems={'center'}>
                      <Flex alignItems={'center'}>
                        <Text className="pair-text">
                          {CHAIN_IDS_TO_SYMBOL[item?.fromChainId]} to {CHAIN_IDS_TO_SYMBOL[item?.toChainId]}
                        </Text>
                        {item.status === TransactionStatus.DONE && updatedDate(item.updatedAt) ? (
                          <TransactionSuccessIcon ml={'10px'} width={'17px'} />
                        ) : (
                          <Text className="txn-processing">{TRANSACTION_STATUS[item?.status] || 'Processing'}</Text>
                        )}
                      </Flex>
                      {index === 0 && (
                        <RiCloseFill
                          color={theme.colors.text}
                          size={'20px'}
                          onClick={() => {
                            setIsShowHistory(false)
                          }}
                        />
                      )}
                    </Flex>
                    <Flex alignItems={'center'} my={'12px'}>
                      <IconImage src={fromTokenInfo?.logoURI} />
                      <Text ml={'10px'} mr={'25px'} className="pair-amount">
                        {Math.trunc(parseFloat(item?.fromAmount) * 1000) / 1000} {fromTokenInfo?.symbol}
                      </Text>
                      <IoIosArrowRoundForward color={theme.colors.text} size={'25px'} />
                      <IconImage src={toTokenInfo?.logoURI} ml={'20px'} />
                      <Text ml={'10px'} className="pair-amount">
                        {Math.trunc(parseFloat(item?.toAmount) * 1000000) / 1000000} {toTokenInfo?.symbol}
                      </Text>
                    </Flex>

                    {/* To check Done Transction */}
                    {item.status === TransactionStatus.DONE ? (
                      <Flex
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        mt={'12px'}
                        mb={index !== 0 && '12px'}
                      >
                        <Text color={theme.colors.success}>{t('Successfully Completed')}</Text>

                        <Text className="estimate" onClick={showHistoryModal}>
                          View history
                        </Text>
                      </Flex>
                    ) : (
                      <>
                        <Flex
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          mt={'12px'}
                          mb={index !== 0 && '12px'}
                        >
                          <Flex alignItems={'center'}>
                            <Text className="estimate">Estimated time:</Text>
                            {item?.type !== 'stable' ? (
                              <Text className="timer" ml={'5px'}>
                                {item?.fromChainId === SupportedChainId.POLYGON ||
                                item?.toChainId === SupportedChainId.POLYGON
                                  ? '20 min'
                                  : '2 min'}
                              </Text>
                            ) : (
                              <Text className="timer" ml={'5px'}>
                                {transferTime} min
                              </Text>
                            )}
                          </Flex>

                          {item?.fromSideTxHash && (
                            <Text
                              className="estimate"
                              onClick={() =>
                                item?.fromSideTxHash &&
                                window.open(
                                  `${NETWORKS_INFO[item?.fromChainId].exploreLink}tx/${item?.fromSideTxHash}`,
                                  '_blank',
                                )
                              }
                            >
                              {t('View Transaction')}
                            </Text>
                          )}
                        </Flex>
                        {/* <ProgressBar now={60} /> */}
                      </>
                    )}
                  </BorderBox>
                )
              })}
            </Card>
          )}
        </Flex>
      )}
    </HistoryWrapper>
  )
}

export default HistoryBox
