import Card from 'components/Card'
import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import TimerComponent from './components/TimeSection'
import { ChainWrapper, DestinationTokenWrapper, IoIosArrowDownIcon, RadioWrapper, SettingIcon } from '../styles'
import BlockchainNetworkFC from './components/BlockchainNetwork'
import { Field } from 'state/types'
import { DotLoader, RepeatIcon } from 'components/Svg'
import PriceInput from './components/PriceInput'
import DestinationTokenInfo from './components/DestinationTokenInfo'
import RouteInfo from './components/Routes'
import Button from 'components/Button'
import { SWAP_STATES } from 'state/swap/types'
import React, { useEffect, useState } from 'react'
import useViewport from 'hooks/useViewport'
import useTheme from 'hooks/useTheme'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { useWalletActionHandlers, useWalletState } from 'state/wallets/hooks'
import { useUserSlippageTolerance } from 'state/user/hooks'
import {
  useAutomaticOnTargetChain,
  useChainInfo,
  useCurrency,
  useFetchNetworkFee,
  useIsAutomaticOnTargetChain,
  useIsValidInput,
  useMinimumAmount,
  useRelayerFee,
  useRelayerFeeDollar,
  useRoutes,
  useSwapInfo,
  useSwapState,
  useUpdateBalance,
} from 'state/swap/selectors'
import { useModal } from 'widgets/Modal'
import WalletModal from 'components/WalletModal'
import SettingsPopup from './components/SettingPopup'
import { useTranslation } from 'contexts/Localization'
import useFetchCurrencyBalance from 'hooks/useFetchCurrencyBalance'
import useSwap from './hooks/useSwap'
import { SupportedChainId } from 'config/constants/chains'
import Form from 'react-bootstrap/Form'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { useCryptoUSDRate, useSwapSelectedTab } from 'state/global/selectors'
import { SWAP_TAB } from 'state/global/types'
import { useGetBlockchainInfo } from 'state/swap/selectors'

const DefaultSwap = () => {
  useSwap()

  const { width } = useViewport()
  const { theme } = useTheme()
  const IsMobile = width <= 990
  const { onSwitchTokens, onUpdateAutomaticOnTargetChain, onUpdateSwapPageState } = useSwapActionHandlers()
  const { onSwitchWallets } = useWalletActionHandlers()
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const walletInfo = useWalletState(Field.INPUT)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [swapDisabled, setSwapDisabled] = useState<boolean>(true)
  const { swapInfo } = useSwapInfo()
  const { routes } = useRoutes()
  const { isValidInput } = useIsValidInput()
  const { minimumAmount } = useMinimumAmount()
  const { isAutomaticOnTargetChain } = useIsAutomaticOnTargetChain()
  const swapPageState = useSwapState()
  const { fromSideNetworkFee, toSideNetworkFee } = useFetchNetworkFee()
  const inputChainId = useChainInfo(Field.INPUT)
  const outputChainId = useChainInfo(Field.OUTPUT)

  const fromSideUSDRate = useCryptoUSDRate(`${inputChainId}`)
  const toSideUSDRate = useCryptoUSDRate(`${outputChainId}`)

  let fromSideNetworkFeeInUsd = 0
  if (fromSideUSDRate) {
    fromSideNetworkFeeInUsd = fromSideNetworkFee.toEther().toNumber() * fromSideUSDRate
  }

  let toSideNetworkFeeInUsd = 0
  if (toSideUSDRate) {
    toSideNetworkFeeInUsd = toSideNetworkFee.toEther().toNumber() * toSideUSDRate
  }
  const [showWalletModal, onWalletDismiss] = useModal(
    <WalletModal
      handleDismiss={() => {
        onWalletDismiss()
      }}
      field={Field.INPUT}
      isForConfirmation={false}
    />,
    true,
  )
  const [onPresentCallbackSettings, onDismissSettings] = useModal(
    <SettingsPopup handleDismiss={() => onDismissSettings()} />,
    Number(userSlippageTolerance.toFixed()) >= 0.5 && Number(userSlippageTolerance.toFixed()) <= 50,
  )
  const [showWalletConfirmationModal, onWalletConfirmationDismiss] = useModal(
    <WalletModal
      handleDismiss={() => {
        // onUpdateSwapPageState(SWAP_STATES.DEFAULT)
        onWalletConfirmationDismiss()
      }}
      field={Field.INPUT}
      isForConfirmation={true}
    />,
    true,
  )
  const { t } = useTranslation()
  const inputCurrencyWithoutBalance = useCurrency(Field.INPUT)
  const { fetchCurrencyBalance } = useFetchCurrencyBalance()
  const [inputCurrency, setInputCurrency] = useState(inputCurrencyWithoutBalance)
  const [balanceLoading, setBalanceLoading] = useState(false)
  const selectedTab = useSwapSelectedTab()

  const pageState = useSwapState()
  const isAutomaticForUI = useAutomaticOnTargetChain()
  const { relayerFee } = useRelayerFee()

  const inputBlockchainInfo = useGetBlockchainInfo(Field.INPUT)
  const fetchBalance = async () => {
    setBalanceLoading(true)
    const inputCurrencyWithBalance = await fetchCurrencyBalance(inputChainId, inputCurrencyWithoutBalance)
    setInputCurrency(inputCurrencyWithBalance)
    setBalanceLoading(false)
  }

  useEffect(() => {
    fetchBalance()
  }, [inputCurrencyWithoutBalance, walletInfo])

  useEffect(() => {
    if (pageState === SWAP_STATES.ROUTES_LOADING) fetchBalance()
  }, [pageState])

  const shouldUpdateBalance = useUpdateBalance()
  useEffect(() => {
    fetchBalance()
  }, [shouldUpdateBalance])

  const isRelayerFeeError =
    routes.length > 0 &&
    swapPageState !== SWAP_STATES.ROUTES_LOADING &&
    isAutomaticOnTargetChain &&
    relayerFee.toEther().toNumber() <= 0

  let swapButtonText // text that needs to be displayed on main 'Swap' button

  const isDisableSwap =
    selectedTab === SWAP_TAB.DEFAULT && [inputChainId, outputChainId].includes(SupportedChainId.TRON)

  if (isDisableSwap) {
    swapButtonText = t('Wallet not supported yet')
  } else if (swapPageState === SWAP_STATES.NO_ROUTES) {
    if (isValidInput || minimumAmount == 0) {
      swapButtonText = t('No liquidity found')
    } else {
      swapButtonText = t('Minimum %am %t required')
        .replace('%am', `${Math.trunc(minimumAmount * 10000000) / 10000000}`)
        .replace('%t', inputCurrency.symbol)
    }
  } else if (swapInfo?.priceImpact && swapInfo?.priceImpact >= 2) {
    swapButtonText = t('Price impact is high, click to swap anyway')
  } else {
    if (isRelayerFeeError) {
      swapButtonText = t('Relayer fee error')
    } else {
      swapButtonText = t('Swap')
    }
  }

  return (
    <Card width={width > 500 ? '481px' : '94%'}>
      <Flex justifyContent="space-between">
        <Text
          fontSize={IsMobile ? '18px' : '24px'}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.semiBold}
          color={theme.colors.text}
        >
          {t('Swap')}
        </Text>
        <Flex ml="auto" justifyContent="center" alignItems="center">
          <TimerComponent setIsRefresh={setIsRefresh} isRefresh={isRefresh} />
          <SettingIcon className="scale-on-hover" onClick={onPresentCallbackSettings} />
        </Flex>
      </Flex>
      <Box mt="14px" mx="6px">
        <Text
          fontSize={IsMobile ? '11px' : '16px'}
          fontFamily={theme.fonts.secondary}
          fontWeight={theme.fonts.regular}
          color={theme.colors.textBlur}
        >
          {t('From Chain')}
        </Text>
        <Flex justifyContent="space-between" mt="7px">
          <ChainWrapper
            bgColor={`${theme.colors.backgroundSubtle}47`}
            onClick={showWalletModal}
            className="fade-on-hover"
          >
            <BlockchainNetworkFC blockchainNetwork={inputBlockchainInfo} />
            <IoIosArrowDownIcon className={'icon'} />
          </ChainWrapper>
          {walletInfo &&
            (balanceLoading ? (
              <DotLoader />
            ) : (
              inputCurrency.balance && (
                <Text
                  fontSize="16px"
                  color={theme.colors.textHeading}
                  mt="auto"
                  fontFamily={theme.fonts.secondary}
                  fontWeight={theme.fonts.regular}
                >
                  {t('Balance')}: {inputCurrency.balance ? Number(inputCurrency.balance.toEther().toFixed(3)) : '0'}
                </Text>
              )
            ))}
        </Flex>
        <PriceInput isRefresh={isRefresh} setSwapDisabled={setSwapDisabled} />
      </Box>
      <Flex mt="0.5rem" alignItems="center" justifyContent={'center'} role="button">
        <RepeatIcon
          onClick={() => {
            onSwitchTokens()
            onSwitchWallets()
          }}
          className="scale-on-hover"
        />
      </Flex>
      <DestinationTokenWrapper>
        {/* to token detial UI  */}
        <DestinationTokenInfo />
        {/* Route Info UI */}
        <RouteInfo setSwapDisabled={setSwapDisabled} />
      </DestinationTokenWrapper>
      {/* {inputChainId !== outputChainId && outputChainId !== SupportedChainId.SOLANA && (
        <>
          <Flex mt={'18px'} justifyContent={'space-between'}>
            <Flex flex={0.13}>
              <Form>
                <RadioWrapper>
                  <Form.Check
                    checked={isAutomaticForUI}
                    onChange={(event) => {
                      onUpdateAutomaticOnTargetChain(event.target.checked)
                    }}
                    style={{
                      color: '#0D0D18',
                    }}
                    type="radio"
                    id={`default-checkbox`}
                  />
                </RadioWrapper>
              </Form>
            </Flex>
            <Flex flex={2}>
              <Text
                fontSize="14px"
                color={theme.colors.text}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.regular}
              >
                Gas on source chain ( Automatic payment )
              </Text>
            </Flex>
            <Flex flex={0.4} justifyContent={'end'}>
              <Flex justifyContent={'center'} alignItems={'center'}>
                <Text
                  fontSize="14px"
                  color={theme.colors.text}
                  fontFamily={theme.fonts.primary}
                  fontWeight={theme.fonts.regular}
                  mr={'5px'}
                >
                  {swapPageState === SWAP_STATES.ROUTES_LOADING ? (
                    <DotLoader />
                  ) : (
                    `~$${Math.trunc(fromSideNetworkFeeInUsd * 100) / 100}`
                  )}
                </Text>
                <AiOutlineInfoCircle
                  data-tip={`<span style="margin-top:15px;">Some amount will be deducted from the swapped asset and <br/> Atlas will pay the transfer fee on destination chain.</span>`}
                  className="pointer info-icon"
                  size="14px"
                  color="white"
                />
                <ReactTooltip
                  textColor={theme.colors.text}
                  data-multiline={true}
                  place="bottom"
                  className="tooltip-xp tooltip-bottom"
                  backgroundColor="linear-gradient(100deg, #56566C 0%, #5A3C48 43%, #7561B9 100%)"
                  effect="solid"
                  html={true}
                  border={true}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex mb={'8px'} justifyContent={'space-between'} alignItems={'center'}>
            <Flex flex={0.13}>
              <Form>
                <RadioWrapper>
                  <Form.Check
                    checked={!isAutomaticForUI}
                    onChange={(event) => {
                      onUpdateAutomaticOnTargetChain(!event.target.checked)
                    }}
                    style={{
                      color: '#0D0D18',
                    }}
                    type="radio"
                    id={`default-checkbox`}
                  />
                </RadioWrapper>
              </Form>
            </Flex>
            <Flex flex={2}>
              <Text
                fontSize="14px"
                color={theme.colors.text}
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.regular}
              >
                Gas on both source and destination chains
              </Text>
            </Flex>
            <Flex flex={0.4} justifyContent={'end'}>
              <Flex justifyContent={'center'} alignItems={'flex-end'} className={'mt-1'}>
                <Text
                  fontSize="14px"
                  color={theme.colors.text}
                  fontFamily={theme.fonts.primary}
                  fontWeight={theme.fonts.regular}
                  mr={'5px'}
                >
                  {swapPageState === SWAP_STATES.ROUTES_LOADING ? (
                    <DotLoader />
                  ) : (
                    `~$${Math.trunc((fromSideNetworkFeeInUsd + toSideNetworkFeeInUsd) * 100) / 100}`
                  )}
                </Text>
                <AiOutlineInfoCircle
                  data-tip={`<span style="margin-top:15px;">User will pay transaction fee on both source and destination chains.</span>`}
                  className="info-icon mb-icon-3 pointer"
                  size="14px"
                  color="white"
                />
              </Flex>
            </Flex>
          </Flex>
        </>
      )} */}
      <Button
        borderRadius="8px"
        padding="0.6rem"
        marginTop={'1.2rem'}
        width={'100%'}
        onClick={() => {
          //changeNetwork(137)
          onUpdateSwapPageState(SWAP_STATES.BEFORE_SWAP_WALLET_CONFIRMATIONS)
          showWalletConfirmationModal()
        }}
        disabled={swapDisabled || isRelayerFeeError || isDisableSwap}
      >
        <Text
          fontSize={IsMobile ? '12px' : '15px'}
          color={theme.colors.text}
          fontFamily={theme.fonts.primary}
          fontWeight={theme.fonts.medium}
        >
          {swapButtonText}
        </Text>
      </Button>
    </Card>
  )
}

export default DefaultSwap
