import { Box, Flex } from 'components/Box'
import useTheme from 'hooks/useTheme'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { useChainInfo, useCurrencies, useCurrency } from 'state/swap/selectors'
import { Field } from 'state/types'
import { Modal } from 'widgets/Modal'
import { Input, ListItem, TokenTag } from './styles'
import { FixedSizeList as List } from 'react-window'
import { Currency } from 'config/entities'
import React, { useEffect, useState } from 'react'
import { Text } from 'components/Text'
import Image from 'components/Image'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import { ShimmerCard } from 'components/Svg'
import useFetchCurrencyBalance from 'hooks/useFetchCurrencyBalance'
import useTokenInfo from 'hooks/useTokenInfo'
import Button from 'components/Button'
import { addCustomToken } from 'utils/addCustomToken'
import { useTranslation } from 'contexts/Localization'
interface ITokenListProps {
  handleDismiss: () => void
  field: Field
}

interface IExtendedCurrency extends Currency {
  custom?: boolean
}

const TokensListModal: React.FC<ITokenListProps> = (props: ITokenListProps) => {
  const { handleDismiss, field } = props
  const chainID = useChainInfo(field)
  const { theme } = useTheme()
  const { fetchCurrenciesBalances } = useFetchCurrencyBalance()
  const oppositeCurrency = useCurrency(field === Field.INPUT ? Field.OUTPUT : Field.INPUT) // Fetch opposite side currency to show disable token
  const currencies = useCurrencies(field)
  const [filteredCurrencies, setFilteredCurrencies] = useState<IExtendedCurrency[]>(() => {
    const TOKENS_FROM_STORAGE = JSON.parse(localStorage.getItem('atlas-custom-tokens'))
    const custom_tokens = TOKENS_FROM_STORAGE ? TOKENS_FROM_STORAGE[chainID] || [] : []
    return [...currencies, ...custom_tokens]
  })
  const [searchString, setSearchString] = useState<string>('')
  const { onCurrencySelection } = useSwapActionHandlers()
  const [, debounceSearch] = useDebouncedChangeHandler(searchString, (value) => search(value))

  const [loading, setLoading] = useState(false)
  const [currenciesWithBalance, setCurrenciesWithBalance] = useState(() => {
    const TOKENS_FROM_STORAGE = JSON.parse(localStorage.getItem('atlas-custom-tokens'))
    const custom_tokens = TOKENS_FROM_STORAGE ? TOKENS_FROM_STORAGE[chainID] || [] : []
    return [...currencies, ...custom_tokens]
  })
  const { fetchTokenInfo } = useTokenInfo()
  const { t } = useTranslation()
  useEffect(() => {
    const fetchBalance = async () => {
      setLoading(true)
      const tokensWithBalance = await fetchCurrenciesBalances(chainID, filteredCurrencies)
      setCurrenciesWithBalance(tokensWithBalance)
      setLoading(false)
    }

    fetchBalance()
  }, [chainID, currencies])

  useEffect(() => {
    // run the debounce function when searchString is changed
    debounceSearch(searchString)
  }, [searchString])

  const search = async (searchStr) => {
    const filteredCurrencies = currenciesWithBalance.filter((currencyObj) => {
      const lowerCaseString = searchStr.toLowerCase()
      return (
        currencyObj.name.toLowerCase().includes(lowerCaseString) ||
        currencyObj.symbol.toLowerCase().includes(lowerCaseString) ||
        currencyObj.address.toLowerCase().includes(lowerCaseString)
      )
    })
    if (filteredCurrencies.length === 0) {
      setLoading(true)
      const tokenInfo = await fetchTokenInfo(chainID, searchStr)
      setLoading(false)
      if (tokenInfo.success) {
        const token: IExtendedCurrency = {
          name: tokenInfo.name,
          symbol: tokenInfo.symbol,
          chainId: chainID,
          address: searchStr,
          decimals: tokenInfo.decimal,
          logoURI: tokenInfo.logoURI ? tokenInfo.logoURI : './images/unknown-token.svg',
          custom: true,
        }
        if (tokenInfo.name) return setFilteredCurrencies([token])
      }
    }
    setFilteredCurrencies(filteredCurrencies)
  }

  const handleSelectCurrency = (token: Currency) => {
    onCurrencySelection(field, token)
    handleDismiss()
  }

  useEffect(() => {
    setFilteredCurrencies(currenciesWithBalance)
  }, [currenciesWithBalance])

  const Row = ({ index, style }) => (
    <div style={style}>
      <ListItem
        key={index}
        className={
          oppositeCurrency.address === filteredCurrencies[index].address &&
          oppositeCurrency.chainId === filteredCurrencies[index].chainId &&
          'disable'
        }
        onClick={() => {
          //if opposite currency is same than return otherwise proceed
          if (
            oppositeCurrency.address === filteredCurrencies[index].address &&
            oppositeCurrency.chainId === filteredCurrencies[index].chainId
          ) {
            return
          }
          handleSelectCurrency(filteredCurrencies[index])
        }}
      >
        <Image src={filteredCurrencies[index].logoURI} classes="rounded-circle symbol-icon" />
        <Box ml="10px">
          <Text className="token-symbol">{filteredCurrencies[index].symbol}</Text>
          <Text className="wallet-name">{filteredCurrencies[index].name}</Text>
        </Box>

        {filteredCurrencies[index].balance && (
          <Text className="balance" ml="auto">
            {Number(filteredCurrencies[index].balance.toEther().toFixed(6)) > 0
              ? Number(filteredCurrencies[index].balance.toEther().toFixed(6))
              : ''}
          </Text>
        )}

        {filteredCurrencies[index].custom && (
          <Button
            className={'ms-auto'}
            onClick={() => {
              const customToken = filteredCurrencies[index]
              delete customToken['custom']
              addCustomToken(customToken, chainID)
            }}
          >
            Import
          </Button>
        )}
      </ListItem>
    </div>
  )
  return (
    <Modal hideCloseButton={false} onDismiss={handleDismiss} title={t('Select a token')} minWidth="500px">
      <Flex mt="0.4rem" flexWrap={'wrap'} p="0 1rem">
        {currenciesWithBalance.slice(0, 6).map((token, index) => (
          <TokenTag
            key={index}
            className={oppositeCurrency.address === token.address && 'disable'}
            onClick={() => {
              //if opposite currency is same than return otherwise proceed
              if (oppositeCurrency.address === token.address) {
                return
              }
              handleSelectCurrency(token)
            }}
          >
            <Image src={token.logoURI} classes="rounded-circle token-icon" />
            <Text className="symbol">{token.symbol}</Text>
          </TokenTag>
        ))}
      </Flex>

      <Box borderRadius={'12px'} background={theme.colors.backgroundAlt} p="4%" mt="1rem">
        <Input
          disabled={loading}
          placeholder={t('Search or add new tokens by pasting token address')}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />

        {loading ? (
          <Box py={'9.6px'}>
            <ShimmerCard
              height={43.5}
              gap={9.6}
              totalCards={6}
              backgroundColor="#00000059"
              foregroundColor="#BDC2C420"
            />
          </Box>
        ) : (
          <>
            {filteredCurrencies.length > 0 ? (
              <List height={380} width="100%" itemSize={55} itemCount={filteredCurrencies.length}>
                {Row}
              </List>
            ) : (
              <Flex alignItems={'center'} justifyContent="center" minHeight={'200px'}>
                <Text fontFamily={theme.fonts.primary}>{t('0 search results found')}</Text>
              </Flex>
            )}
          </>
        )}
      </Box>
    </Modal>
  )
}

export default TokensListModal
