import { IoIosArrowRoundForward } from 'react-icons/io'
import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import useViewport from 'hooks/useViewport'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import Image from 'components/Image'
import { useChainInfo, useFetchNetworkFee, useRoutes, useSwapInfo, useSwapState } from 'state/swap/selectors'
import { InfoRow } from '../../../styles'
import { BridgeIcon } from 'components/Svg'
import { SWAP_STATES } from 'state/swap/types'
import React, { useState } from 'react'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { Field } from 'state/types'
import { useCryptoUSDRate } from 'state/global/selectors'
import { SupportedChainId } from 'config/constants/chains'
import { getNativeCurrency } from 'utils/getNativeCurrency'

const SwapInfo = () => {
  const { swapInfo } = useSwapInfo()
  const { theme } = useTheme()
  const { width } = useViewport()
  const { t } = useTranslation()
  const { routes } = useRoutes()
  const pageState = useSwapState()
  const [slippage] = useUserSlippageTolerance()
  const [expanded, setExpanded] = useState(false)
  const inputChainId = useChainInfo(Field.INPUT)
  const outputChainId = useChainInfo(Field.OUTPUT)

  const { toSideNetworkFee } = useFetchNetworkFee()

  const toSideUSDRate = useCryptoUSDRate(`${outputChainId}`)

  let toSideNetworkFeeInUsd = 0
  if (toSideUSDRate) {
    toSideNetworkFeeInUsd = toSideNetworkFee.toEther().toNumber() * toSideUSDRate
  }

  return (
    <>
      {routes.length > 0 && swapInfo && (
        <Box
          mb="2rem"
          width={width > 500 ? '451px' : '94%'}
          style={{ opacity: pageState === SWAP_STATES.ROUTES_LOADING ? 0.6 : 1 }}
        >
          <InfoRow>
            <Text className="route-text">{t('Minimum Received')}</Text>
            <Text className="route-text">{swapInfo.minimumReceived.toEther().toFixed(5)}</Text>
          </InfoRow>
          {inputChainId !== outputChainId && outputChainId !== SupportedChainId.SOLANA && (
            <>
              <InfoRow>
                <Text className="route-text">{t('Transfer fee')}</Text>
                <Text className="route-text">{`${toSideNetworkFee.toEther().toFixed(5)} ${
                  getNativeCurrency[outputChainId].symbol
                }`}</Text>
              </InfoRow>

              <InfoRow>
                <Text className="route-text">{t('Transfer fee in USD')}</Text>
                <Text className="route-text">~${Math.trunc(toSideNetworkFeeInUsd * 100) / 100}</Text>
              </InfoRow>
            </>
          )}

          <>
            {swapInfo.priceImpact && swapInfo.priceImpact !== 0 ? (
              <InfoRow>
                <Text
                  className="route-text"
                  color={
                    swapInfo.priceImpact < 2
                      ? theme.colors.text
                      : swapInfo.priceImpact >= 2 && swapInfo.priceImpact < 5
                      ? theme.colors.textOrange
                      : theme.colors.failure
                  }
                >
                  {t('Price Impact')}
                </Text>
                <Text
                  className="route-text"
                  color={
                    swapInfo.priceImpact < 2
                      ? theme.colors.text
                      : swapInfo.priceImpact >= 2 && swapInfo.priceImpact < 5
                      ? theme.colors.textOrange
                      : theme.colors.failure
                  }
                >
                  {swapInfo.priceImpact} %
                </Text>
              </InfoRow>
            ) : null}
          </>
          {expanded && (
            <>
              <InfoRow>
                <Text className="route-text">{t('Route')}</Text>
                <Flex alignItems="center">
                  {swapInfo.route.map((url, index) => (
                    <div key={index}>
                      {url === 'Bridge' ? (
                        <BridgeIcon width="20" />
                      ) : (
                        <Image src={url} width="20" height="20" classes="rounded-circle" />
                      )}
                      {index !== swapInfo.route.length - 1 && <IoIosArrowRoundForward color={theme.colors.text} />}
                    </div>
                  ))}
                </Flex>
              </InfoRow>
              <InfoRow>
                <Text className="route-text">
                  {swapInfo.fromSymbol} {t('Price')}
                </Text>
                <Text className="route-text">{swapInfo.fromRate}</Text>
              </InfoRow>
              <InfoRow>
                <Text className="route-text">
                  {swapInfo.toSymbol} {t('Price')}
                </Text>
                <Text className="route-text">{swapInfo.toRate}</Text>
              </InfoRow>
              <InfoRow>
                <Text className="route-text">{t('Slippage tolerance')}</Text>
                <Text className="route-text">{slippage.toFixed()}%</Text>
              </InfoRow>
              <InfoRow>
                <Text className="route-text">
                  {t('Estimated time')}
                  <AiOutlineInfoCircle
                    data-tip={`<strong style="margin-top:15px;">Delays could be experienced in case of network congestion.</strong>`}
                    className="ms-1 info-icon"
                    size="16px"
                  />
                  <ReactTooltip
                    textColor={theme.colors.text}
                    data-multiline={true}
                    place="top"
                    className="tooltip-xp tooltip-top-left"
                    backgroundColor="linear-gradient(100deg, #56566C 0%, #5A3C48 43%, #7561B9 100%)"
                    effect="solid"
                    html={true}
                    border={true}
                  />
                </Text>

                <Text className="route-text">{swapInfo.estimatedTime}</Text>
              </InfoRow>
            </>
          )}

          <Flex alignItems={'center'} justifyContent={'center'} width={'100%'}>
            <Text
              fontFamily={theme.fonts.secondary}
              fontWeight={theme.fonts.regular}
              color={theme.colors.success}
              role={'button'}
              onClick={() => setExpanded(!expanded)}
            >
              {t(`Show ${expanded ? 'less' : 'more'}`)}
            </Text>
          </Flex>
        </Box>
      )}
    </>
  )
}

export default SwapInfo
