import { useEffect } from 'react'
import {
  useActiveStep,
  useActiveStepIndex,
  useActiveStepState,
  useIsAutomaticOnTargetChain,
  useSteps,
  useSwapState,
  useTransactionId,
} from 'state/swap/selectors'
import {
  ACTION_STEP_STATE,
  IActionStepState,
  IRoute,
  IStep,
  SWAP_ACTIONS,
  SWAP_STATES,
  UPDATE_ACTIONS,
} from 'state/swap/types'
import { changeNetwork } from 'utils/wallet'
import useApproveCallback from 'hooks/useApproveCallback'
import { useEVMSwapData } from './useEVMSwapData'
import { useSwapActionHandlers } from 'state/swap/hooks'
import useAtlasDexSwapEVM from './useAtlasDexSwapEVM'
import useJupiter from './useJupiter'
import { useAtlasDexSwapLockEVM } from './useAtlasDexSwapLockEVM'
import { useWormholeBridge } from './useWormholeBridge'
import { useAtlasDexSwapUnlock } from './useAtlasDexSwapUnlockEVM'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useHandleRedeem from './useHandleRedeem'
import { normalizeAmount } from 'utils'
import { CurrencyAmount } from 'config/entities'
import * as Sentry from '@sentry/react'
import { usePostError } from 'utils/postLogs'
import { useUpdateTransaction } from 'views/Swap/Default/hooks/postTransactions'
import { TransactionStatus } from 'config/enums/transactionStatus'

export const useSwap = () => {
  const { library } = useActiveWeb3React()
  const { handleEVMSwapData } = useEVMSwapData()
  const { activeStepIndex } = useActiveStepIndex()
  const { activeStepState } = useActiveStepState()
  const { handleEvmSwap } = useAtlasDexSwapEVM()
  const { onAtlasDexSwapLock } = useAtlasDexSwapLockEVM()
  const { onAtlasDexSwapUnlock } = useAtlasDexSwapUnlock()
  const { handleJupiterSwap, lockOnSolanaSide, swapUnlockedSolana, onAssociateAccountCreate } = useJupiter()
  const pageState = useSwapState()
  const { step } = useActiveStep(activeStepIndex)
  const { steps } = useSteps()
  const { approve } = useApproveCallback()
  const { onActionStepStateChanged, onNextStep, onUpdateSwapPageState, onUpdateShouldFetchTransaction } =
    useSwapActionHandlers()
  const { onWormholeBridgeActions } = useWormholeBridge()
  const { redeem } = useHandleRedeem()
  const { postError } = usePostError()
  const { updateTransaction } = useUpdateTransaction()
  const { transactionId } = useTransactionId()

  const { isAutomaticOnTargetChain } = useIsAutomaticOnTargetChain()
  useEffect(() => {
    if (pageState === SWAP_STATES.SWAP_IN_PROGRESS && activeStepState.status === ACTION_STEP_STATE.IN_PROGRESS) {
      handleSwapSteps(step)
    }
  }, [pageState, activeStepState])

  useEffect(() => {
    if (activeStepState.status === ACTION_STEP_STATE.WAIT_FOR_LIBRARY) {
      onActionStepStateChanged({
        status: ACTION_STEP_STATE.IN_PROGRESS,
        message: 'In progress',
      })
    }
  }, [library])

  async function handleSwapSteps(step: IStep) {
    try {
      let actionStepState: IActionStepState = {
        status: ACTION_STEP_STATE.ERROR,
        message: 'Something went wrong',
      }
      if (step.name === SWAP_ACTIONS.CHANGE_NETWORK) {
        actionStepState = await changeNetwork(step.payload.chainId)
      } else if (step.name === SWAP_ACTIONS.APPROVE_CONTRACT) {
        actionStepState = await approve(step.payload.amount, step.payload.inputCurrency)
      } else if (step.name === SWAP_ACTIONS.SWAP) {
        // here this step means on same EVM to EVM swap when occur.
        actionStepState = await handleEVMSwapData(step.payload)
        if (actionStepState.status === ACTION_STEP_STATE.OK) {
          // Locked done.
          actionStepState = await handleEvmSwap(actionStepState.payload, step.payload.inputCurrency)
          updateTransaction(
            transactionId,
            TransactionStatus.PROCESSING,
            actionStepState.payload.txid,
            actionStepState.payload.txid,
          )
        } // end of if
      } else if (step.name === SWAP_ACTIONS.SWAP_SOLANA) {
        actionStepState = await handleJupiterSwap(step.payload)
        const route: IRoute = step.payload
        // update state only if we are performing same chain swap
        if (actionStepState.status === ACTION_STEP_STATE.OK) {
          updateTransaction(
            transactionId,
            route.inputCurrency.chainId === route.outputCurrency.chainId
              ? TransactionStatus.DONE
              : TransactionStatus.PENDING,
            actionStepState.payload.txid,
            actionStepState.payload.txid,
          )
        }
      } else if (step.name === SWAP_ACTIONS.LOCK_SOLANA) {
        const prevDependingStep = steps.find((stepObj) => stepObj.name === step.dependOnStep)
        const resultForPrev = {
          outputAmount: step.payload.sourceInputAmount,
        }

        if (prevDependingStep && prevDependingStep.result) {
          resultForPrev['outputAmount'] = new CurrencyAmount(
            prevDependingStep.result.outputAmount,
            step.payload.inputLockCurrency.decimal,
          )
        }
        actionStepState = await lockOnSolanaSide(step.payload, resultForPrev)
        actionStepState.status === ACTION_STEP_STATE.OK &&
          updateTransaction(transactionId, TransactionStatus.BLOCK_CONFIRMATION, actionStepState.payload.txid)
      } else if (step.name === SWAP_ACTIONS.LOCK_EVM) {
        // here calling for EVM to EVM first locking
        actionStepState = await onAtlasDexSwapLock(step.payload)
        actionStepState.status === ACTION_STEP_STATE.OK &&
          updateTransaction(transactionId, TransactionStatus.BLOCK_CONFIRMATION, actionStepState.payload.txid)
      } // end of else if for lock action
      else if (step.name === SWAP_ACTIONS.BRIDGE) {
        // here it means locked done, now we need to verify tx confirmed so need to wait for confirmation and then get VAA
        const prevDependingStep = steps.find((stepObj) => stepObj.name === step.dependOnStep)
        actionStepState = await onWormholeBridgeActions(prevDependingStep ? prevDependingStep.result : null)
      } else if (step.name === SWAP_ACTIONS.UNLOCK_EVM) {
        // here it means locked done, now we need to verify tx confirmed so need to wait for confirmation and then get VAA
        const prevDependingStep = steps.find((stepObj) => stepObj.name === step.dependOnStep)
        actionStepState = await onAtlasDexSwapUnlock(step.payload, prevDependingStep ? prevDependingStep.result : null)
        actionStepState.status === ACTION_STEP_STATE.OK &&
          updateTransaction(transactionId, TransactionStatus.PROCESSING, undefined, actionStepState.payload.txid)
      } else if (step.name === SWAP_ACTIONS.UNLOCK_SOLANA) {
        const prevDependingStep = steps.find((stepObj) => stepObj.name === step.dependOnStep)
        actionStepState = await redeem(prevDependingStep ? prevDependingStep.result : null)
      } else if (step.name === SWAP_ACTIONS.SWAP_UNLOCKED_SOLANA) {
        const prevDependingStep = steps.find((stepObj) => stepObj.name === step.dependOnStep)
        const normalizedLockedAmount = normalizeAmount(
          prevDependingStep.result.lockedAmount,
          step.payload.inputLockCurrency.decimals,
          step.payload.outputMintCurrency.decimals,
        )
        actionStepState = await swapUnlockedSolana(step.payload, normalizedLockedAmount)
        actionStepState.status === ACTION_STEP_STATE.OK &&
          updateTransaction(transactionId, TransactionStatus.PROCESSING, undefined, actionStepState.payload.txid)
      } else if (step.name === SWAP_ACTIONS.RELAYER) {
        actionStepState = {
          status: ACTION_STEP_STATE.WAITING,
          message: 'In progress',
        }
      } else if (step.name === SWAP_ACTIONS.CREATE_ASSOCIATE_ACCOUNT) {
        actionStepState = await onAssociateAccountCreate(step.payload.outputMintCurrency)
      }
      // update state on response if ok
      if (actionStepState.status === ACTION_STEP_STATE.OK) {
        //if it is last step then update action and page state
        if (activeStepIndex === steps.length - 1) {
          onUpdateSwapPageState(SWAP_STATES.SWAP_COMPLETED)
          onUpdateShouldFetchTransaction(UPDATE_ACTIONS.FIRST_TIME)

          onActionStepStateChanged(actionStepState)
        } else {
          // update to next step
          onNextStep(actionStepState)
        }
      } else if (actionStepState.status !== ACTION_STEP_STATE.WAITING) {
        // in case of error just update action state
        if (actionStepState.status !== ACTION_STEP_STATE.WAIT_FOR_LIBRARY) {
          if (!isAutomaticOnTargetChain) {
            updateTransaction(transactionId, TransactionStatus.FAILED)
          }
          postError(actionStepState.message, JSON.stringify(actionStepState.error), step.name, JSON.stringify(step))
        }
        onActionStepStateChanged(actionStepState)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

export default useSwap
