import { Box, Flex } from 'components/Box'
import { Text } from 'components/Text'
import { ShimmerCard } from 'components/Svg'
import { SWAP_STATES } from 'state/swap/types'
import { useReceivedAmount, useStableSwapState } from 'state/stableSwap/selectors'
import useTheme from 'hooks/useTheme'
import { CHAIN_KEYS } from 'config/constants/chains'
import { useChainInfo } from 'state/stableSwap/selectors'
import { Field } from 'state/types'
import React from 'react'
import { MessengerKeyDTO } from 'config/allbridge/client/core-api/core-api.model'
import { useTranslation } from 'contexts/Localization'
import { RouteWrapper } from 'views/Swap/Default/components/Routes/style'

const QuoteBox: React.FC<{ isDisableSwap: boolean }> = ({ isDisableSwap }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()

  const swapPageState = useStableSwapState()
  const receiveAmount = useReceivedAmount()

  const inputChainId = useChainInfo(Field.INPUT)
  const outputChainId = useChainInfo(Field.OUTPUT)

  return (
    <RouteWrapper>
      {swapPageState === SWAP_STATES.ROUTES_LOADING && !isDisableSwap ? (
        <Box mt={'10px'} pb={'17px'}>
          <ShimmerCard height={68.38} gap={10} totalCards={0} backgroundColor="#00000059" foregroundColor="#BDC2C420" />
        </Box>
      ) : (
        receiveAmount.toEther().gt(0) && (
          <Flex
            className={'inner-section pointer select'}
            mt="10px"
            position="relative"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex flexDirection="column">
              <Text
                fontSize="16px"
                color={theme.colors.textTertiary}
                fontFamily={theme.fonts.secondary}
                fontWeight={theme.fonts.regular}
              >
                {MessengerKeyDTO.ALLBRIDGE}
              </Text>
              <Text
                fontSize="12px"
                color={theme.colors.textTertiary}
                fontFamily={theme.fonts.secondary}
                fontWeight={theme.fonts.light}
              >
                {CHAIN_KEYS[inputChainId]} - {CHAIN_KEYS[outputChainId]}
              </Text>
            </Flex>
            <Box>
              <Text
                fontSize="18px"
                color={theme.colors.success}
                textAlign="center"
                fontFamily={theme.fonts.primary}
                fontWeight={theme.fonts.bold}
              >
                {Math.trunc(parseFloat(receiveAmount.toEther().toString()) * 10000) / 10000}
              </Text>
              <Box className="best-price" position="absolute" top="0px" right="0px">
                <Text
                  fontSize="12px"
                  color={theme.colors.dark}
                  textAlign="center"
                  fontFamily={theme.fonts.secondary}
                  fontWeight={theme.fonts.bold}
                >
                  {t('Best Price')}
                </Text>
              </Box>
            </Box>
          </Flex>
        )
      )}
    </RouteWrapper>
  )
}

export default QuoteBox
