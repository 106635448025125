import { useCallback } from 'react'
import { getMulticallAddress } from 'utils/addressHelpers'
import erc20Abi from 'config/abi/erc20.json'
import { multicallv2 } from 'utils/multicall'
import { CurrencyAmount } from 'config/entities/fractions/CurrencyAmount'
import useActiveWeb3React from './useActiveWeb3React'
import { SupportedChainId, SupportedEVMChainId } from 'config/constants/chains'
import { ethers } from 'ethers'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import { Currency } from 'config/entities'
import * as Sentry from '@sentry/react'

import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'

const useFetchCurrencyBalance = () => {
  const { account } = useActiveWeb3React()
  const { publicKey } = useWallet()
  const { connection } = useConnection()

  function prepareMultiCall(currency, chainId) {
    if (currency.address === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
      return {
        address: getMulticallAddress(chainId),
        name: 'getEthBalance',
        params: [account],
      }
    }
    return {
      address: currency.address,
      name: 'balanceOf',
      params: [account],
    }
  }

  async function handleMultiCalls(currencies, chainId, provider) {
    const balanceCalls = currencies.map((currency) => {
      return prepareMultiCall(currency, chainId)
    })
    try {
      const balanceResults = await multicallv2(erc20Abi, balanceCalls, chainId, provider)
      return currencies.map((currency, index) => {
        return {
          ...currency,
          balance: new CurrencyAmount(balanceResults[index], currency.decimals),
        }
      })
    } catch (error) {
      Sentry.captureException(error)
    }
    return currencies
  }

  const fetchEVMBalance = async (chainId, currencies) => {
    const providerURL = getEVMNodeURL[chainId]
    const provider = new ethers.providers.JsonRpcProvider(providerURL)

    const _currenciesWithBalance = await handleMultiCalls(currencies, chainId, provider)
    return _currenciesWithBalance.sort((a, b) => b.balance.toEther() - a.balance.toEther())
  }

  const fetchSolBalance = async (chainId, currencies) => {
    try {
      const parsedTokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
        programId: TOKEN_PROGRAM_ID,
      })
      const solBalance = await connection.getBalance(publicKey)
      const currenciesWithBalance = currencies.map((currency, index) => {
        if (currency.address === '11111111111111111111111111111111') {
          return {
            ...currency,
            balance: new CurrencyAmount(solBalance, currencies[0].decimals),
          }
        } else {
          const accountInfo = parsedTokenAccounts.value.find((account) => {
            return account.account.data.parsed.info.mint === currency.address
          })
          const tokenAmount = accountInfo
            ? accountInfo.account.data.parsed.info.tokenAmount
            : {
                amount: 0,
                decimals: currency.decimals,
              }
          return {
            ...currency,
            balance: new CurrencyAmount(tokenAmount.amount, tokenAmount.decimals),
          }
        }
      })
      return currenciesWithBalance.sort((a, b) => Number(b.balance.toEther()) - Number(a.balance.toEther()))
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const getTronTokenBalance = async (address) => {
    if (window.tronWeb) {
      const tronWeb = window.tronWeb
      const contract = await tronWeb.contract().at(address)
      const balance = await contract.balanceOf(tronWeb.defaultAddress.base58).call()
      return balance.toString()
    } else {
      return '0'
    }
  }

  const fetchTronBalance = async (chainId, currencies) => {
    try {
      const currenciesWithBalance = await Promise.all(
        currencies.map(async (currency) => {
          const balance = await getTronTokenBalance(currency.address)
          return {
            ...currency,
            balance: new CurrencyAmount(balance, currency.decimals),
          }
        }),
      )
      return currenciesWithBalance.sort((a, b) => Number(b.balance.toEther()) - Number(a.balance.toEther()))
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const fetchCurrenciesBalances = useCallback(
    async (chainId, currencies): Promise<Currency[]> => {
      if (SupportedEVMChainId(chainId)) {
        if (ethers.utils.isAddress(account)) {
          return await fetchEVMBalance(chainId, currencies)
        }
      } else if (chainId === SupportedChainId.TRON) {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) return await fetchTronBalance(chainId, currencies)
      } else {
        if (publicKey) return await fetchSolBalance(chainId, currencies)
      }
      return currencies
    },
    [connection, publicKey, account],
  )

  const fetchCurrencyBalance = useCallback(
    async (chainId, currency): Promise<Currency> => {
      let currencies = []

      if (SupportedEVMChainId(chainId)) {
        if (ethers.utils.isAddress(account)) {
          currencies = await fetchEVMBalance(chainId, [currency])
        }
      } else if (chainId === SupportedChainId.TRON) {
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          currencies = await fetchTronBalance(chainId, [currency])
        }
      } else {
        if (publicKey) {
          currencies = await fetchSolBalance(chainId, [currency])
        }
      }
      if (currencies && currencies.length > 0) {
        return currencies[0]
      } else {
        return {
          ...currency,
          balance: new CurrencyAmount(0, currency.decimals),
        }
      }
    },
    [connection, publicKey, account],
  )

  return { fetchCurrenciesBalances, fetchCurrencyBalance }
}

export default useFetchCurrencyBalance
