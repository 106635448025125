import { useCallback } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { ONE_INCH_API_URL } from 'config/constants/endpoints'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { Fee, FeeCollector } from 'config/enums'
import { getAtlasDexSwapAddress } from 'utils/addressHelpers'
import { CurrencyAmount } from 'config/entities'

const use1InchSwap = () => {
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const handle1InchSwap = useCallback(
    async (
      chainId: number,
      account: string,
      fromTokenAddress: string,
      toTokenAddress: string,
      amount: CurrencyAmount,
    ) => {
      try {
        if (fromTokenAddress.toLowerCase() !== toTokenAddress.toLowerCase()) {
          const atlasDexAddress = getAtlasDexSwapAddress(chainId)

          const urlForSwap = `${ONE_INCH_API_URL}${chainId}/swap?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${amount
            .toWei()
            .toString()}&destReceiver=${account}&disableEstimate=true&fromAddress=${atlasDexAddress}&fee=${
            Fee.DEFAULT
          }&referrerAddress=${FeeCollector.EVM_FEE_COLLECTOR}&slippage=${userSlippageTolerance.toFixed()}`

          Sentry.addBreadcrumb({
            type: 'EVM_SWAP_1inch',
            message: `Call inch for Swap`,
            data: {
              url: urlForSwap,
            },
          })
          const { data } = await axios(urlForSwap)
          return { data: data.tx.data, value: data.tx.value }
        } else {
          return { data: '0x00', value: '0' }
        }
      } catch (error) {
        console.log(error)
        Sentry.captureException(error)
        throw error
      }
    },
    [userSlippageTolerance],
  )
  return { handle1InchSwap }
}

export default use1InchSwap
