import { Box, Flex } from 'components/Box'
import React, { useEffect } from 'react'
import Page from 'views/Page'
import useViewport from 'hooks/useViewport'
import { SwapWrapper } from './styles'
import SwapInfo from './Default/components/SwapInfo'
import HistoryBox from './Default/components/PendingTransactions'
import { useSwapTransactionsHandler } from 'state/swap/hooks/user.transactions.history'
import DefaultSwap from './Default'
import { Button } from 'components/Header/styles'
import StableSwapInfo from './StableSwap/components/StableSwapInfo'
import { useSwapSelectedTab } from 'state/global/selectors'
import { SWAP_TAB } from 'state/global/types'
import { useGlobalActionHandlers } from 'state/global/hooks'
import { useNextQuery } from 'hooks/useNextQuery'
import StableSwap from './StableSwap'
import { useAllBrideStableSwapDataSync } from 'state/stableSwap/hooks'

export default function Swap() {
  useSwapTransactionsHandler()
  const { width } = useViewport()
  const selectedSwapTab = useSwapSelectedTab()
  const { onSelectSwapTab } = useGlobalActionHandlers()

  useNextQuery()
  useAllBrideStableSwapDataSync()
  return (
    <Page removePadding style={{ flex: 1 }}>
      <SwapWrapper
        width="100%"
        flexDirection={width > 1000 ? 'row' : 'column-reverse'}
        justifyContent={width > 1000 ? 'space-between' : 'center'}
        alignItems="flex-start"
        height={'100%'}
      >
        <Flex flex={1}></Flex>
        <Flex
          flex={1}
          width={'100%'}
          mb="45px"
          mt="45px"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Flex width={width > 500 ? '481px' : '94%'} mb={'20px'}>
            <Button isUnSelect={selectedSwapTab === SWAP_TAB.STABLE} onClick={() => onSelectSwapTab(SWAP_TAB.DEFAULT)}>
              <Box>Swap</Box>
            </Button>
            <Button isUnSelect={selectedSwapTab !== SWAP_TAB.STABLE} onClick={() => onSelectSwapTab(SWAP_TAB.STABLE)}>
              <Box>Stable Swap</Box>
            </Button>
          </Flex>
          {selectedSwapTab === SWAP_TAB.STABLE ? <StableSwap /> : <DefaultSwap />}

          {selectedSwapTab === SWAP_TAB.STABLE ? <StableSwapInfo /> : <SwapInfo />}
        </Flex>
        <HistoryBox />
      </SwapWrapper>
    </Page>
  )
}
