import { useCallback } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { ZERO_X_API_URL } from 'config/constants/endpoints'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { Fee, FeeCollector } from 'config/enums'
import { CurrencyAmount } from 'config/entities'
import { getAtlasDexSwapAddress } from 'utils/addressHelpers'

export const use0xSwap = () => {
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const handle0xSwap = useCallback(
    async (chainId: number, fromTokenAddress: string, toTokenAddress: string, amount: CurrencyAmount) => {
      try {
        if (fromTokenAddress.toLowerCase() !== toTokenAddress.toLowerCase()) {
          const atlasDexAddress = getAtlasDexSwapAddress(chainId)

          const url = `${
            ZERO_X_API_URL[chainId]
          }swap/v1/quote?sellToken=${fromTokenAddress}&buyToken=${toTokenAddress}&sellAmount=${amount
            .toWei()
            .toString()}&takerAddress=${atlasDexAddress}&slippagePercentage=${userSlippageTolerance
            .divide(100)
            .toFixed(3)}&fee=${Fee.DEFAULT}&referrerAddress=${FeeCollector.EVM_FEE_COLLECTOR}&skipValidation=true`

          Sentry.addBreadcrumb({
            type: 'EVM_SWAP_0x',
            message: `Call 0x for Swap`,
            data: {
              url,
            },
          })
          const { data } = await axios(url)
          return { data: data.data, value: data.value }
        } else {
          return { data: '0x00', value: '0' }
        }
      } catch (error) {
        Sentry.captureException(error)
        throw error
      }
    },
    [userSlippageTolerance],
  )
  return { handle0xSwap }
}
