import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { CHAIN_ID_SOLANA, createNonce, hexToUint8Array, tryNativeToHexString } from '@certusone/wormhole-sdk'
import { useAppDispatch } from 'state'
import { CurrencyAmount } from 'config/entities'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { ACTION_STEP_STATE, IActionStepState, IRoute } from 'state/swap/types'
import { useEVMSwapData } from './useEVMSwapData'
import { NETWORKS_INFO, SupportedChainId } from 'config/constants/chains'
import { getAtlasDexSwapAddress, getWormholeTokenBridgeAddress } from 'utils/addressHelpers'
import { calculateGasMargin, getProviderOrSigner } from 'utils'
import { getAtlasDexSwapContract } from 'utils/contractHelpers'
import { TransactionResponse } from '@ethersproject/providers'
import { getTransactionReceipt } from 'utils/callHelper'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useSwapActionHandlers } from 'state/swap/hooks'
import { PublicKey } from '@solana/web3.js'
import { useIsAutomaticOnTargetChain, useRelayerFee, useTransactionId } from 'state/swap/selectors'
import { ethers } from 'ethers'
import { useUserSlippageTolerance } from 'state/user/hooks'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const abiDecoder = require('abi-decoder')
import atlasDexSwapAbi from 'config/abi/AtlasDexSwap.json'

// AtlasDex Swap Contract call to lock token.
export const useAtlasDexSwapLockEVM = () => {
  abiDecoder.addABI(atlasDexSwapAbi) // adding this add ABI here.

  const dispatch = useAppDispatch()
  const { account, library } = useActiveWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { handleEVMSwapData } = useEVMSwapData()
  const { publicKey } = useWallet()
  const { onSetStepResult } = useSwapActionHandlers()
  const { connection: solanaConnectionObj } = useConnection()
  const { isAutomaticOnTargetChain } = useIsAutomaticOnTargetChain()
  const [userSlippageTolerance] = useUserSlippageTolerance()
  const { relayerFee } = useRelayerFee()

  const { transactionId: transactionIdOfDB } = useTransactionId()
  const handleSwapLock = useCallback(
    async (routeInfo: IRoute): Promise<IActionStepState> => {
      try {
        const atlasDexAddress = getAtlasDexSwapAddress(routeInfo.inputCurrency.chainId)
        const atlasDexSwapContract = getAtlasDexSwapContract(atlasDexAddress, getProviderOrSigner(library, account))
        const actionStepState = await handleEVMSwapData({
          ...routeInfo,
          outputCurrency: routeInfo.inputLockCurrency,
        })
        if (actionStepState.status !== ACTION_STEP_STATE.OK) {
          throw new Error(actionStepState.message)
        }

        const targetChainId = NETWORKS_INFO[routeInfo.outputCurrency.chainId].wormholeChainId
        const _nonce = createNonce()
        let nativeHexString
        if (targetChainId === CHAIN_ID_SOLANA) {
          const tokenAccountsForLockCurrency = await solanaConnectionObj.getParsedTokenAccountsByOwner(
            publicKey,
            {
              mint: new PublicKey(routeInfo.outputMintCurrency.address),
            },
            'confirmed',
          )

          if (tokenAccountsForLockCurrency.value.length < 1) {
            throw new Error('Associate Token Info not Found')
          }

          nativeHexString = tryNativeToHexString(tokenAccountsForLockCurrency.value[0].pubkey.toBase58(), targetChainId)
        } else {
          nativeHexString = tryNativeToHexString(account, targetChainId)
        }
        const hexString = hexToUint8Array(nativeHexString)
        const targetAddress = Buffer.from(hexString)
        const amountInBigNumber = routeInfo.sourceInputAmount.toWei().toString()
        const overrides = {
          // The amount to send with the transaction (i.e. msg.value)
          value: actionStepState.payload.value,
        }

        let lockedDataParams = {
          _wormholeBridgeToken: getWormholeTokenBridgeAddress(routeInfo.inputCurrency.chainId),
          _token: routeInfo.inputLockCurrency.address,
          _amount: amountInBigNumber,
          _recipientChain: targetChainId,
          _recipient: targetAddress,
          _nonce,
          _1inchData: actionStepState.payload._1inchData,
          _0xData: actionStepState.payload._0xData,
          _IsWrapped: actionStepState.payload._IsWrapped,
          _IsUnWrapped: actionStepState.payload._IsUnWrapped,
          _amountToUnwrap: actionStepState.payload._amount,
          _payload: '0x00',
        }

        if (isAutomaticOnTargetChain) {
          const targetChainAtlasDexAddress = getAtlasDexSwapAddress(routeInfo.outputCurrency.chainId)

          const targetChainAtlasDexInByte32 = tryNativeToHexString(targetChainAtlasDexAddress, targetChainId)

          const targetAddressInByte32 = Buffer.from(hexToUint8Array(targetChainAtlasDexInByte32))

          const targetCurrencyInByte32 = Buffer.from(
            hexToUint8Array(tryNativeToHexString(routeInfo.outputCurrency.address, targetChainId)),
          )

          const transactionIdForPayload = ethers.utils.formatBytes32String(transactionIdOfDB)
          const slippage = +userSlippageTolerance.toFixed(2) * 1000 // this thousand is to remove floating so to send on contract.
          const fee = relayerFee.toWei().toFixed(0)
          const abiCoder = new ethers.utils.AbiCoder()
          const _payloadForCrossChain = abiCoder.encode(
            ['bytes32', 'bytes32', 'bytes32', 'uint256', 'uint256'],
            [targetAddress, targetCurrencyInByte32, transactionIdForPayload, slippage, fee],
          )

          lockedDataParams = {
            _wormholeBridgeToken: getWormholeTokenBridgeAddress(routeInfo.inputCurrency.chainId),
            _token: routeInfo.inputLockCurrency.address,
            _amount: amountInBigNumber,
            _recipientChain: targetChainId,
            _recipient: targetAddressInByte32,
            _nonce,
            _1inchData: actionStepState.payload._1inchData,
            _0xData: actionStepState.payload._0xData,
            _IsWrapped: actionStepState.payload._IsWrapped,
            _IsUnWrapped: actionStepState.payload._IsUnWrapped,
            _amountToUnwrap: actionStepState.payload._amount,
            _payload: _payloadForCrossChain,
          }
        }

        const estimatedGas = await atlasDexSwapContract.estimateGas.lockedTokens(lockedDataParams, overrides)
        const gasPrice = await library.getGasPrice()
        let enhancedPrice = calculateGasMargin(gasPrice)

        if (
          routeInfo.inputCurrency.chainId === SupportedChainId.POLYGON ||
          routeInfo.inputCurrency.chainId === SupportedChainId.FANTOM
        ) {
          enhancedPrice = calculateGasMargin(gasPrice.mul(2))
        }

        const response: TransactionResponse = await callWithGasPrice(
          atlasDexSwapContract,
          'lockedTokens',
          [lockedDataParams],
          {
            gasLimit: calculateGasMargin(estimatedGas),
            value: actionStepState.payload.value,
            gasPrice: enhancedPrice,
          },
        )
        onSetStepResult({
          status: ACTION_STEP_STATE.IN_PROGRESS,
          message: 'IN-Progress',
          payload: {
            txid: response.hash,
          },
        })
        //:TODO implement later getting tx if dropped.
        const receipt = await getTransactionReceipt(library, response.hash, routeInfo.inputCurrency.chainId, account)

        const atlasDexRouterLogs = receipt['logs'].filter(
          (log) => log.address.toLowerCase() === atlasDexAddress.toLowerCase(),
        )
        const decodedLogs = abiDecoder.decodeLogs(atlasDexRouterLogs)

        let lockedAmount = routeInfo.sourceInputAmount

        if (decodedLogs.length > 0) {
          lockedAmount = new CurrencyAmount(decodedLogs[0].events[1]['value'], routeInfo.sourceOutputAmount.decimals)
        }
        return {
          status: ACTION_STEP_STATE.OK,
          message: 'Success',
          payload: { receipt, txid: receipt.transactionHash, lockedAmount, chainId: routeInfo.inputCurrency.chainId },
        }
      } catch (error) {
        console.error('----', error)
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [
      dispatch,
      library,
      account,
      publicKey,
      isAutomaticOnTargetChain,
      transactionIdOfDB,
      relayerFee,
      userSlippageTolerance,
    ],
  )

  return { onAtlasDexSwapLock: handleSwapLock }
}
