import { Box } from 'components/Box'
import { Text } from 'components/Text'
import useViewport from 'hooks/useViewport'
import { useTranslation } from 'contexts/Localization'
import { InfoRow } from '../../../styles'
import React, { useEffect, useState } from 'react'
import { useStableChainInfo, useSwapFee } from 'state/stableSwap/selectors'
import { Field } from 'state/types'
import { CHAIN_KEYS } from 'config/constants/chains'
import { useChainInfo } from 'state/stableSwap/selectors'
import { Messenger } from 'config/allbridge'
import { useCryptoUSDRate } from 'state/global/selectors'
import { getNativeCurrency } from 'utils/getNativeCurrency'
import ReactTooltip from 'react-tooltip'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import useTheme from 'hooks/useTheme'

const StableSwapInfo = () => {
  const { width } = useViewport()
  const { t } = useTranslation()
  const { theme } = useTheme()

  const chainID = useChainInfo(Field.INPUT)
  const outputChainId = useChainInfo(Field.OUTPUT)

  const swapFee = useSwapFee()
  const rates = useCryptoUSDRate(`${chainID}`)
  const stableChainInfo = useStableChainInfo(CHAIN_KEYS[chainID])
  const [transferTime, setTransferTime] = useState('0')

  useEffect(() => {
    if (outputChainId && stableChainInfo && stableChainInfo?.transferTime) {
      try {
        console.info(
          '---[Messenger.ALLBRIDGE] ---',
          stableChainInfo?.transferTime[CHAIN_KEYS[outputChainId]][Messenger.ALLBRIDGE],
        )
        const _totalTime = stableChainInfo?.transferTime[CHAIN_KEYS[outputChainId]][Messenger.ALLBRIDGE]
        const date = new Date(0)
        date.setMinutes(Math.floor(_totalTime / 1000 / 60)) // specify value for SECONDS here
        setTransferTime(date.toISOString().substring(14, 19))
      } catch (error) {
        console.error(error)
      }
    }
  }, [stableChainInfo, outputChainId])

  return (
    <>
      <Box mb="2rem" width={width > 500 ? '451px' : '94%'}>
        <InfoRow>
          <Text className="route-text">
            {t('Transfer fee')}
            <AiOutlineInfoCircle
              data-tip={`<strong style="margin-top:15px;">Payment for the receiving transaction on</br> the destination chain</strong>`}
              className="ms-1 info-icon"
              size="16px"
            />
          </Text>
          <Text className="route-text">
            {Math.trunc(parseFloat(swapFee.toEther().toString()) * 10000) / 10000} {getNativeCurrency[chainID].symbol}
          </Text>
        </InfoRow>
        {rates && (
          <InfoRow>
            <Text className="route-text">
              {t('Transfer fee in USD')}
              <AiOutlineInfoCircle
                data-tip={`<strong style="margin-top:15px;">Payment for the receiving transaction on</br> the destination chain in dollar</strong>`}
                className="ms-1 info-icon"
                size="16px"
              />
              <ReactTooltip
                textColor={theme.colors.text}
                multiline={true}
                place="top"
                class="tooltip-xp tooltip-top-left"
                backgroundColor="linear-gradient(100deg, #56566C 0%, #5A3C48 43%, #7561B9 100%)"
                effect="solid"
                html={true}
                border={true}
              />
            </Text>
            <Text className="route-text">{Math.trunc(swapFee.toEther().toNumber() * rates * 10000) / 10000} USD</Text>
          </InfoRow>
        )}
        <InfoRow>
          <Text className="route-text">
            {t('Average transfer time')}
            <AiOutlineInfoCircle
              data-tip={`<strong style="margin-top:15px;">The average time of the cross-chain</br> swap based on the historic transfer data</strong>`}
              className="ms-1 info-icon"
              size="16px"
            />
          </Text>
          <Text className="route-text">{transferTime} min</Text>
        </InfoRow>
        <ReactTooltip
          textColor={theme.colors.text}
          multiline={true}
          place="top"
          class="tooltip-xp tooltip-top-left"
          backgroundColor="linear-gradient(100deg, #56566C 0%, #5A3C48 43%, #7561B9 100%)"
          effect="solid"
          html={true}
          border={true}
        />
      </Box>
    </>
  )
}

export default StableSwapInfo
