import { Box } from 'components/Box'
import styled from 'styled-components'

export const TokenWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: 12px;
  cursor: pointer;
  padding: 0.7rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    margin-left: 0.5rem;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 0.6rem;
    border-radius: 4px;
  }
`
