import { useEffect } from 'react'
import { ACTION_STEP_STATE, IActionStepState, IStep, SWAP_ACTIONS, SWAP_STATES } from 'state/swap/types'
import { changeNetwork } from 'utils/wallet'
import useApproveCallback from 'hooks/useApproveCallback'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import * as Sentry from '@sentry/react'
import { usePostError } from 'utils/postLogs'
import { useUpdateTransaction } from 'views/Swap/Default/hooks/postTransactions'
import {
  useActiveStep,
  useActiveStepIndex,
  useActiveStepState,
  useStableSwapState,
  useSteps,
  useTransactionId,
} from 'state/stableSwap/selectors'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'
import useStableSwapHandler from './useHandleStableSwap'
import { SupportedChainId } from 'config/constants/chains'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import { Field } from 'state/types'
import { AllbridgeCoreSdk } from 'config/allbridge'
const sdk = new AllbridgeCoreSdk()

export const useStableSwap = () => {
  const { library } = useActiveWeb3React()
  const activeStepIndex = useActiveStepIndex()
  const activeStepState = useActiveStepState()
  const step = useActiveStep(activeStepIndex)
  const steps = useSteps()
  const pageState = useStableSwapState()

  const { handleStableSwap } = useStableSwapHandler()

  const { approve } = useApproveCallback()
  const { onActionStepStateChanged, onNextStep, onUpdateSwapPageState } = useStableSwapActionHandlers()
  const { postError } = usePostError()
  const { updateTransaction } = useUpdateTransaction()
  const { getConnectedWalletAddress } = useWalletActionHandlers()

  useEffect(() => {
    if (pageState === SWAP_STATES.SWAP_IN_PROGRESS && activeStepState.status === ACTION_STEP_STATE.IN_PROGRESS) {
      handleSwapSteps(step)
    }
  }, [pageState, activeStepState])

  useEffect(() => {
    if (activeStepState.status === ACTION_STEP_STATE.WAIT_FOR_LIBRARY) {
      onActionStepStateChanged({
        status: ACTION_STEP_STATE.IN_PROGRESS,
        message: 'In progress',
      })
    }
  }, [library])

  async function handleSwapSteps(step: IStep) {
    const activeUserAddress = getConnectedWalletAddress(Field.INPUT)

    try {
      let actionStepState: IActionStepState = {
        status: ACTION_STEP_STATE.ERROR,
        message: 'Something went wrong',
      }
      if (!step) return
      if (step.name === SWAP_ACTIONS.CHANGE_NETWORK) {
        actionStepState = await changeNetwork(step.chainId)
      } else if (step.name === SWAP_ACTIONS.APPROVE_CONTRACT) {
        //add new property to object

        if (step.payload.inputChainId === SupportedChainId.TRON) {
          try {
            const tronWeb = window.tronWeb
            const isAlreadyAllownce = await sdk.checkAllowance(tronWeb, {
              amount: step.payload.amount.toEther().toString(),
              tokenInfo: step.payload.inputCurrency,
              owner: activeUserAddress,
            })
            if (!isAlreadyAllownce) {
              await sdk.approve(tronWeb, {
                tokenAddress: step.payload.inputCurrency.tokenAddress,
                owner: activeUserAddress,
                spender: step.payload.inputCurrency.poolAddress,
              })
            }

            actionStepState = {
              status: ACTION_STEP_STATE.OK,
              message: 'Approved',
            }
          } catch (error) {
            actionStepState = {
              status: ACTION_STEP_STATE.ERROR,
              message: 'Fail Approval',
            }
          }
        } else {
          actionStepState = await approve(
            step.payload.amount,
            {
              address: step.payload.inputCurrency.tokenAddress,
              decimals: step.payload.inputCurrency.decimals,
              symbol: step.payload.inputCurrency.symbol,
              name: step.payload.inputCurrency.name,
              chainId: step.payload.inputChainId,
              logoURI: '',
            },
            step.payload.inputCurrency.poolAddress,
            false,
          )
        }
      } else if (step.name === SWAP_ACTIONS.SWAP) {
        actionStepState = await handleStableSwap(
          step.chainId,
          step.payload.outputChainId,
          step.payload.inputCurrency,
          step.payload.outputCurrency,
          step.payload.amount,
        )

        // end of if
      } else if (step.name === SWAP_ACTIONS.BRIDGE) {
        actionStepState = {
          status: ACTION_STEP_STATE.WAITING,
          message: 'In progress',
        }
      }

      // update state on response if ok
      if (actionStepState.status === ACTION_STEP_STATE.OK) {
        //if it is last step then update action and page state
        if (activeStepIndex === steps.length - 1) {
          onUpdateSwapPageState(SWAP_STATES.SWAP_COMPLETED)
          onActionStepStateChanged(actionStepState)
        } else {
          // update to next step
          onNextStep(actionStepState)
        }
      } else if (actionStepState.status !== ACTION_STEP_STATE.WAITING) {
        // in case of error just update action state
        if (actionStepState.status !== ACTION_STEP_STATE.WAIT_FOR_LIBRARY) {
          // updateTransaction(transactionId, TransactionStatus.FAILED)
          // postError(actionStepState.message, JSON.stringify(actionStepState.error), step.name, JSON.stringify(step))
        }
        onActionStepStateChanged(actionStepState)
      }
    } catch (error) {
      Sentry.captureException(error)
      console.error('=== ', error)
      onActionStepStateChanged({
        status: ACTION_STEP_STATE.ERROR,
        message: error['message'],
      })
    }
  }
}

export default useStableSwap
