import { Currency } from 'config/entities'

/**
 * Function to add a new token in localStorage
 * @param customToken the token to be added
 * @param chainID the chainID that token belongs to
 */
export const addCustomToken = (customToken: Currency, chainID) => {
  const tokensFromStorage = localStorage.getItem('atlas-custom-tokens')

  if (tokensFromStorage) {
    const tokensList = JSON.parse(tokensFromStorage)
    let tokenOfChain = tokensList[chainID] || []
    const newToken = customToken
    delete newToken['custom']
    tokenOfChain = [...tokenOfChain, newToken]
    tokensList[chainID] = tokenOfChain
    localStorage.setItem('atlas-custom-tokens', JSON.stringify(tokensList))
  } else {
    // user is adding the token for the first time
    const CUSTOM_CURRENCIES = {
      [chainID]: [customToken],
    }
    localStorage.setItem('atlas-custom-tokens', JSON.stringify(CUSTOM_CURRENCIES))
  }
}
