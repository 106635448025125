import { useChainInfo, useCurrency } from 'state/swap/selectors'
import { Field } from '../state/types'
import { useRouter } from 'next/router'
import { useAppDispatch } from 'state'
import { useEffect } from 'react'
import { setChainAndToken } from 'state/swap/actions'
import { CHAIN_KEYS, SupportedChainId } from '../config/constants/chains'
import {
  AvalancheTokens,
  BSCTokens,
  EthereumTokens,
  FantomTokens,
  PolygonTokens,
  SolanaTokens,
} from '../config/tokenLists'
import { Currency } from '../config/entities'
import { useSwapSelectedTab } from 'state/global/selectors'
import { useGlobalActionHandlers } from 'state/global/hooks'
import { SWAP_TAB } from 'state/global/types'
import { useChainInfo as useStableChainInfo, useCurrency as useStableCurrency } from 'state/stableSwap/selectors'
import { ChainDetailsMap, TokenInfoWithChainDetails } from 'config/allbridge'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'
import axios from 'axios'

export const useNextQuery = () => {
  const router = useRouter()
  const { query } = router

  const dispatch = useAppDispatch()
  const { onSelectSwapTab } = useGlobalActionHandlers()
  const { onSetChainToken } = useStableSwapActionHandlers()
  // for default swap
  const toToken = useCurrency(Field.OUTPUT)
  const toChain = useChainInfo(Field.OUTPUT)
  const fromToken = useCurrency(Field.INPUT)
  const fromChain = useChainInfo(Field.INPUT)
  const selectedTab = useSwapSelectedTab()
  //for stable swap
  const _toToken = useStableCurrency(Field.OUTPUT)
  const _fromToken = useStableCurrency(Field.INPUT)
  const _toChain = useStableChainInfo(Field.OUTPUT)
  const _fromChain = useStableChainInfo(Field.INPUT)

  useEffect(() => {
    if (selectedTab === SWAP_TAB.STABLE) {
      router.push(
        router.pathname,
        {
          query: {
            fromChain: _fromChain,
            toChain: _toChain,
            toToken: _toToken?.tokenAddress,
            fromToken: _fromToken?.tokenAddress,
            tab: selectedTab,
          },
        },
        { shallow: true },
      )
    } else {
      router.push(
        router.pathname,
        { query: { fromChain, toChain, toToken: toToken.address, fromToken: fromToken.address, tab: selectedTab } },
        { shallow: true },
      )
    }
    // params need to be changed when these 4 (toChain, fromChain, toToken, fromToken) variables change
  }, [toChain, fromChain, toToken, fromToken, _fromChain, _toChain, _toToken, _fromToken, selectedTab])

  useEffect(() => {
    const UpdateStateFromQuery = async () => {
      const toChain = Number(query?.toChain)
      const fromChain = Number(query?.fromChain)
      if (query?.tab === SWAP_TAB.STABLE) {
        try {
          const {
            data: { supportedChains },
          } = await axios(`/api/allbridge/supported-chains`)
          const _inputCurrencyList = supportedChains ? supportedChains[CHAIN_KEYS[fromChain]]?.tokens : []
          const _outputCurrencyList = supportedChains ? supportedChains[CHAIN_KEYS[toChain]]?.tokens : []
          const _fromCurrency: TokenInfoWithChainDetails = _inputCurrencyList.find(
              (e) => e.tokenAddress === query?.fromToken,
            ),
            _toCurrency: TokenInfoWithChainDetails = _outputCurrencyList.find((e) => e.tokenAddress === query?.toToken)
          if (!_fromCurrency || !_toCurrency) return

          onSetChainToken(
            Number(query?.fromChain),
            Number(query?.toChain),
            _fromCurrency,
            _toCurrency,
            _inputCurrencyList,
            _outputCurrencyList,
          )
          onSelectSwapTab(SWAP_TAB.STABLE)
        } catch (error) {
          console.error('ERROR IN UPDATE IN NEXT QUERY', error)
        }
      } else {
        let toCurrencies, fromCurrencies
        if (toChain === SupportedChainId.BINANCE) {
          toCurrencies = BSCTokens
        } else if (toChain === SupportedChainId.ETHEREUM) {
          toCurrencies = EthereumTokens
        } else if (toChain === SupportedChainId.POLYGON) {
          toCurrencies = PolygonTokens
        } else if (toChain === SupportedChainId.AVAX) {
          toCurrencies = AvalancheTokens
        } else if (toChain === SupportedChainId.FANTOM) {
          toCurrencies = FantomTokens
        } else if (toChain === SupportedChainId.SOLANA) {
          toCurrencies = SolanaTokens
        }
        if (fromChain === SupportedChainId.BINANCE) {
          fromCurrencies = BSCTokens
        } else if (fromChain === SupportedChainId.ETHEREUM) {
          fromCurrencies = EthereumTokens
        } else if (fromChain === SupportedChainId.POLYGON) {
          fromCurrencies = PolygonTokens
        } else if (fromChain === SupportedChainId.AVAX) {
          fromCurrencies = AvalancheTokens
        } else if (fromChain === SupportedChainId.FANTOM) {
          fromCurrencies = FantomTokens
        } else if (fromChain === SupportedChainId.SOLANA) {
          fromCurrencies = SolanaTokens
        }

        const fromCurrency: Currency = fromCurrencies.find((e) => e.address === query?.fromToken),
          toCurrency: Currency = toCurrencies.find((e) => e.address === query?.toToken)

        if (!fromCurrency || !toCurrency) return
        dispatch(
          setChainAndToken({
            toChain: Number(query?.toChain),
            fromChain: Number(query?.fromChain),
            toToken: toCurrency,
            fromToken: fromCurrency,
            toCurrencies,
            fromCurrencies,
          }),
        )
        onSelectSwapTab(SWAP_TAB.DEFAULT)
      }
    }
    query?.toChain && query?.fromChain && query?.toToken && query?.fromToken && query?.tab && UpdateStateFromQuery()
    // whenever `query` changes, change the values from state
  }, [query])
}
