import { useCallback } from 'react'
import * as Sentry from '@sentry/react'
import { CurrencyAmount } from 'config/entities'
import { useWalletActionHandlers } from 'state/wallets/hooks'
import { Field } from 'state/types'
import { useTransactionId } from 'state/stableSwap/selectors'
import { AllbridgeCoreSdk, Messenger, production } from 'config/allbridge'
import { TokenInfoWithChainDetails } from 'config/allbridge/tokens-info'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ACTION_STEP_STATE, IActionStepState } from 'state/swap/types'
import { SupportedChainId } from 'config/constants/chains'
import { EvmBridge } from 'config/allbridge/bridge/evm'
const allBridgeSdk = new AllbridgeCoreSdk()
import Web3 from 'web3'
import { getEVMNodeURL } from 'utils/getRpcUrl'
import { calculateGasMargin, getSigner } from 'utils'
import { getTransactionReceipt, waitForSolanaTxConfirmation } from 'utils/callHelper'
import { Transaction } from '@solana/web3.js'
import { TronBridge } from 'config/allbridge/bridge/trx'
import { useStableSwapActionHandlers } from 'state/stableSwap/hooks'
import { usePostTransaction } from './usePostTransactions'
import { TransactionStatus as TransactionStatusInDb } from 'config/enums/transactionStatus'
import { sleep } from 'config/allbridge/bridge/utils'
import axios from 'axios'

const useStableSwapHandler = () => {
  const { getConnectedWalletAddress } = useWalletActionHandlers()
  const { onSetStepResult } = useStableSwapActionHandlers()
  const fromAddress = getConnectedWalletAddress(Field.INPUT)
  const toAddress = getConnectedWalletAddress(Field.OUTPUT)
  const { connection: solanaConnectionObj } = useConnection()

  const { updateStableTransaction } = usePostTransaction()
  const { publicKey, signTransaction } = useWallet()
  const { account, library } = useActiveWeb3React()

  const transactionIdOfDB = useTransactionId()

  const handleStableSwap = useCallback(
    async (
      inputChainId: SupportedChainId,
      outputChainId: SupportedChainId,
      inputCurrency: TokenInfoWithChainDetails,
      outputCurrency: TokenInfoWithChainDetails,
      amount: CurrencyAmount,
    ): Promise<IActionStepState> => {
      try {
        let txId = ''
        if (inputChainId === SupportedChainId.SOLANA) {
          const {
            data: { rawTransaction },
          } = await axios({
            method: 'POST',
            url: `/api/allbridge/solana/raw-transaction`,
            data: {
              sourceChainToken: inputCurrency,
              destinationChainToken: outputCurrency,
              amount: amount.toEther().toString(),
              fromAccountAddress: fromAddress,
              toAccountAddress: toAddress,
              messenger: Messenger.ALLBRIDGE,
            },
          })

          const transactionData = Transaction.from(Buffer.from(rawTransaction, 'base64'))
          transactionData.feePayer = publicKey
          const latestBlockHashResult = await solanaConnectionObj.getRecentBlockhash('confirmed')

          transactionData.recentBlockhash = latestBlockHashResult.blockhash

          const signedTx = await signTransaction(transactionData)
          txId = await solanaConnectionObj.sendRawTransaction(signedTx.serialize())
          onSetStepResult({
            status: ACTION_STEP_STATE.IN_PROGRESS,
            message: 'IN-Progress',
            payload: {
              txid: txId,
            },
          })

          await updateStableTransaction(transactionIdOfDB, TransactionStatusInDb.IN_QUEUE, txId)
          await waitForSolanaTxConfirmation(txId, 'confirmed', solanaConnectionObj, 20)
        } else if (inputChainId === SupportedChainId.TRON) {
          const tronBridge = new TronBridge(window.tronWeb, allBridgeSdk.api)

          const rawTransaction = await tronBridge.buildRawTransactionSend({
            sourceChainToken: inputCurrency,
            destinationChainToken: outputCurrency,
            amount: amount.toEther().toString(),
            fromAccountAddress: fromAddress,
            toAccountAddress: toAddress,
            messenger: Messenger.ALLBRIDGE,
          })

          const singedTx = await window.tronWeb.trx.sign(rawTransaction)
          if (!singedTx.signature) {
            throw new Error('Transaction signature is not found')
          }

          const receipt = await window.tronWeb.trx.sendRawTransaction(singedTx)

          const transactionHash = receipt.transaction.txID
          onSetStepResult({
            status: ACTION_STEP_STATE.IN_PROGRESS,
            message: 'IN-Progress',
            payload: {
              txid: transactionHash,
            },
          })
          const start = Date.now()
          /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition */
          while (true) {
            if (Date.now() - start > 30000) {
              throw new Error('Transaction not found')
            }
            const result = await window.tronWeb.trx.getUnconfirmedTransactionInfo(transactionHash)
            if (!result?.receipt) {
              await sleep(2000)
              continue
            }
            if (result.receipt.result === 'SUCCESS') {
              break // as success so breaking
            } else {
              /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
              throw new Error(`Transaction status is ${result.receipt.result}`)
            }
          }
          await updateStableTransaction(transactionIdOfDB, TransactionStatusInDb.IN_QUEUE, transactionHash)
        } else {
          // here means evm
          const web3 = new Web3(getEVMNodeURL[inputChainId])
          const evmBridge = new EvmBridge(web3, allBridgeSdk.api)
          const rawTransaction = await evmBridge.buildRawTransactionSend({
            sourceChainToken: inputCurrency,
            destinationChainToken: outputCurrency,
            amount: amount.toEther().toString(),
            fromAccountAddress: fromAddress,
            toAccountAddress: toAddress,
            messenger: Messenger.ALLBRIDGE,
          })
          const signer = getSigner(library, account)
          const gasPrice = await library.getGasPrice()
          const enhancedPrice = calculateGasMargin(gasPrice)
          const txHash = await signer.sendUncheckedTransaction({
            from: rawTransaction['from'],
            to: rawTransaction['to'],
            value: rawTransaction['value'],
            data: rawTransaction['data'],
            chainId: inputChainId,
            gasPrice: enhancedPrice,
          })
          onSetStepResult({
            status: ACTION_STEP_STATE.IN_PROGRESS,
            message: 'IN-Progress',
            payload: {
              txid: txHash,
            },
          })
          await updateStableTransaction(transactionIdOfDB, TransactionStatusInDb.IN_QUEUE, txHash)
          txId = txHash
          await getTransactionReceipt(library, txHash, inputChainId, account)
        }

        return {
          status: ACTION_STEP_STATE.OK,
          message: '',
          payload: {
            txId,
          },
        }
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        return {
          status: ACTION_STEP_STATE.ERROR,
          message: error['message'],
          error: error,
        }
      }
    },
    [fromAddress, toAddress, library, solanaConnectionObj, publicKey, transactionIdOfDB],
  )
  return { handleStableSwap }
}

export default useStableSwapHandler
